import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { CForm, CFormLabel, CFormInput, CButton, CListGroup, CListGroupItem, CFormCheck } from '@coreui/react';
import AxiosInstance from 'src/components/Axios';
import { confirmAlert } from 'react-confirm-alert';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

// Компонент для працівнивів (jobs)
const JobWorkers = (props) => {
    // console.log('props JobWorkers', props);

    // Статуси для тестів
    const statusTestArr = [
        { value: 'new', label: 'ТЕСТ НЕ ПРОЙДЕНО. Статус "Щойно створено"', emodji: '❌' },
        { value: 'in_progress', label: 'ТЕСТ НЕ ПРОЙДЕНО. Статус "Передано в бот для проходження"', emodji: '❌' },
        { value: 'completed', label: 'Тест пройдено в боті', emodji: '🕒' },
        { value: 'synced', label: 'Передано в 1с', emodji: '✅' },
    ];

    const [workerList, setWorkerList] = useState([]);
    const [worker, setWorker] = useState([]);
    const popupTextAddWorkerSuccess = "Працівника додано";

    useEffect(() => {
        getWorkersList();
    }, []);

    // Запит за працівниками у вибраного контрагента
    const getWorkersList = async (totalItems) => {
        let rez = await AxiosInstance.get(`/workers?pagination=false&accountWorkers.account.id=${props.data?.account.id}`, {
            headers: { accept: 'application/json' },
        }).then((response) => {
            // console.log('response workers get', response);
            return response.data.map((result) => ({
                label: result.fullName,
                value: result.id,
            }))
        });
        setWorkerList(rez)
    };

    const handleSave = () => {
        let rez = AxiosInstance.post(`/job_workers`, {
            job: `/api/jobs/${props.data.id}`,
            worker: `/api/workers/${worker.value}`,
            dateJob: '2023-10-30T10:29:13.491Z',
        }).then((response) => {
            props.getData()
            // Повідомлення про успішне додавання працівника
            props.popup.toastifySuccess(popupTextAddWorkerSuccess)
        })
    }

    // Видалити ('/job_workers/' + row.id)
    function Deleted(row) {
        confirmAlert({
            title: 'Увага!',
            message: 'Ви впевнені що хочите видалити цей пункт?',
            buttons: [
                {
                    label: 'Так',
                    onClick: () => {
                        const response = AxiosInstance.delete('/job_workers/' + row.id).then((response) => {
                            // Popup про успішні зміни
                            props.popup.toastifySuccess('Працівника видалено')
                            props.getData()
                        })
                    },
                },
                {
                    label: 'Ні',
                    onClick: () => {
                        return
                    },
                },
            ],
        })
    }

    return (
        <>
            <p className="h4">Працівники</p>

            {/* props.isDisabledAll={true} - Заблокувати можливу зміну, оскільки дані вже вневені в 1С */}
            {
                !props.isDisabledAll &&
                <div className="col d-flex justify-content-end">
                    <Select
                        options={workerList}
                        value={worker}
                        onChange={(value) => setWorker(value)}
                        className="w-25"
                        isDisabled={props.isDisabledChange}
                    />
                    <CButton onClick={handleSave} className="ms-3 mb-2" color="secondary" disabled={worker.length == 0 || props.isDisabledChange}>
                        +
                    </CButton>
                </div>
            }

            <CListGroup className="mb-3">
                {props.jobWorkers != undefined &&
                    props.jobWorkers.map((val) => {
                        // console.log('val', val);
                        // console.log('val.testDate', val.testDate);
                        // console.log('val.dateJob', val.dateJob);

                        let testStatus = null;
                        let testDate = val?.testDate ? dayjs.utc(val.testDate).format('YYYY-MM-DD HH:mm:ss') : null;
                        // let testDate = val?.dateJob ? dayjs.utc(val.dateJob).format('YYYY-MM-DD HH:mm:ss') : null;
                        let emodji = null;

                        // const myStatus = "new";
                        // const myStatus = "in_progress";
                        // const myStatus = "completed";
                        // const myStatus = "synced";

                        // if (myStatus) {
                        if (val?.testStatus) {
                            // const foundStatus = statusTestArr.find((status) => status.value === myStatus);
                            const foundStatus = statusTestArr.find((status) => status.value === val?.testStatus);
                            if (foundStatus) {
                                testStatus = foundStatus.label;
                                emodji = foundStatus.emodji;
                            };
                        };

                        return (
                            <CListGroupItem
                                key={val.id}
                                className="d-flex justify-content-between align-items-center"
                            >
                                <Link to={`/workers/update/${val.worker.id}`} className="ms-3">
                                    {val.worker.fullName}
                                </Link>

                                {/* Інформація про тестування */}
                                {
                                    (testStatus && (props.role === 'ROLE_ADMIN')) &&
                                    <div className='ms-auto me-3' title={testStatus}>{emodji} {testDate && `(${testDate})`}</div>
                                }

                                {/* props.isDisabledAll={true} - Заблокувати можливу зміну, оскільки дані вже вневені в 1С */}
                                {
                                    !props.isDisabledAll &&
                                    <CButton
                                        color="danger"
                                        variant="outline"
                                        onClick={() => Deleted(val)}
                                        disabled={props.isDisabledChange}
                                    >
                                        Видалити
                                    </CButton>
                                }
                            </CListGroupItem>
                        )
                    })}
            </CListGroup>
        </>
    );
};

export default JobWorkers;