import React, { useEffect, useState } from 'react';
import { CForm, CFormLabel, CFormInput, CButton, CListGroup, CListGroupItem, CFormCheck, CFormTextarea } from '@coreui/react';
import { Link, useLoaderData, useNavigate } from 'react-router-dom';
import DangerWorks from './DangerWorks';
import ResponsibleForLaborProtection from './ResponsibleForLaborProtection';
import WorkSchedule from './WorkSchedule';
import ProtocolOP from './ProtocolOP';
import JobWorkers from './JobWorkers';
import JobData from './JobData';
import { confirmAlert } from 'react-confirm-alert';
import AxiosInstance from 'src/components/Axios';
import AdditionalMaterials from './AdditionalMaterials';
import ButtonsBlock from './ButtonsBlock';
import WarningWhenChecked from './WarningWhenChecked';

// Компонент для існуючої роботи
const ExistJobForm = (props) => {
    // console.log('ExistJobForm props', props);

    const urlApi = '/jobs'
    const urlRouter = '/jobs'
    const urlMedia = '/media_objects'
    const urlApiEngineers = '/engineers'

    // токен
    const tokenStr = localStorage.getItem('user')
    const header = { headers: { Authorization: `Bearer ${tokenStr}`, accept: 'application/json' } }

    // id користувача
    const us = localStorage.getItem('user_data')
    // const role = localStorage.getItem('user_role')

    const navigate = useNavigate()

    const [commandmentState, setCommandmentState] = useState(null)
    const [graphState, setGraphState] = useState(null)
    const [dataState, setDataState] = useState(props.data)
    const [myDataState, setMyDataState] = useState(props.data)
    const [protocoleState, setProtocoleState] = useState(null)
    const [jobWorkers, setJobWorkers] = useState(props.data != undefined ? props.data.jobWorkers : [])
    const [isChecked, setIsChecked] = useState(props.data?.AccountJobsList ? true : false)
    const [accJobsList, setAccJobsList] = useState(
        props.data?.AccountJobsList ? [...props.data.AccountJobsList] : [],
    )
    // Блокувати можливу зміну даних, якщо data.status === "checked"
    const [isDisabledChange, setIsDisabledChange] = useState(false)
    // Чи відправити документи на перевірку Адміну
    const [isSendForInspection, setIsSendForInspection] = useState(false)
    const [isDisabledSend, setIsDisabledSend] = useState(false)
    // Зауваження від адміністратора до доданих файлів
    const [comentar, setComentar] = useState(dataState?.comentar ? dataState.comentar : "");
    // Заблокувати можливу зміну, оскільки дані вже вневені в 1С
    const [isDisabledAll, setIsDisabledAll] = useState(false);

    // Відкрити/закрити вікно з попередженням про зміну status="checked" - можливі подальші зміни тільки в 1С
    const [openWarning, setOpenWarning] = useState(false);

    const popupTextDocumentSuccess = "Дані оновлено";
    const popupTextAddFileSuccess = "Файл додано";
    const popupTextDeleteFileSuccess = 'Файл видалено';
    const popupTextAddComentarSuccess = "Зауваження додано";

    // useEffect(() => {
    //     console.log('props.data.status', props.data.status);
    //     console.log('dataState.status', dataState.status);
    //     console.log('isDisabledChange', isDisabledChange);
    //     console.log('isSendForInspection', isSendForInspection);
    //     console.log('isDisabledSend', isDisabledSend);
    // }, [props.data.status, isDisabledChange, isSendForInspection, isDisabledSend, dataState]);

    const [file, setFile] = useState(null)
    // const [arrFile, setArrFile] = useState(props.data ? [...props.data.mediaObjects] : [])
    const [arrFile, setArrFile] = useState(dataState ? [...dataState.mediaObjects] : [])

    // Блокувати можливу зміну даних, якщо data.status === "checked"
    // Чи відправлено на перевірку
    useEffect(() => {
        // console.log('dataState', dataState);

        if (dataState != undefined && (dataState?.status === "new")) {
            setIsDisabledChange(false);
            setIsSendForInspection(false);
        }

        if (dataState != undefined && dataState?.status === "not_checked") {
            setIsDisabledChange(true);
            setIsSendForInspection(true);
        }

        if (dataState != undefined && (dataState?.status === "rejected")) {
            setIsDisabledChange(true);
            setIsSendForInspection(true);
        }

        // Заблокувати можливу зміну, оскільки дані вже вневені в 1С
        if (dataState != undefined && (dataState?.status === "synced" || dataState?.status === "checked")) {
            setIsDisabledAll(true);
        }


        // Оновити Зауваження (коментар) для доданих файлів
        setComentar(dataState?.comentar ? dataState.comentar : "");
    }, [dataState]);


    // Відслідковувати зміну наявних документів, чи відправити документи на перевірку Адміну
    useEffect(() => {
        if (myDataState != undefined && myDataState.commandment != undefined && myDataState.graph != undefined && myDataState.protocole != undefined) {
            // setIsSendForInspection(true)
            setIsDisabledSend(false)
        } else {
            // setIsSendForInspection(false)
            setIsDisabledSend(true)
        }
    }, [myDataState, myDataState.commandment, myDataState.graph, myDataState.protocole])


    // useEffect(() => {
    //   console.log('selectedOptionEngineers', selectedOptionEngineers)
    // }, [selectedOptionEngineers])


    // Функція для запиту за даними про роботу
    const getData = () => {
        AxiosInstance.get(`${urlApi}/${props.data.id}`, { headers: { accept: 'application/json' } }).then(
            (response) => {
                setJobWorkers(response.data.jobWorkers)
                setDataState(response.data)

                response.data.AccountJobsList ? setAccJobsList(response.data.AccountJobsList) : null
                setAccJobsList(response.data.AccountJobsList)
            },
        )
    }


    // useEffect(() => {
    //   loadOptionsPartners();
    // }, [userId])


    // // додавання файлів
    // const handleFileChange = (event) => {
    //   const myFile = event.target.files[0]

    //   setFile(myFile)
    // }


    // додавання файлів обовязкових
    const handleFileChangeState = (event, setState) => {
        const myFile = event.target.files[0]
        setState(myFile)
    }


    // відправити файл
    const handleSubmitAddFile = async (e) => {
        e.preventDefault()

        try {
            // Створити URL для відправки файлу
            const url = process.env.REACT_APP_SERVER_URL + '/api/media_objects?job=' + props.data.id

            // формуємо файл
            if (file) {
                const formData = new FormData()
                formData.append('file', file)
                formData.append('name', file.name)
                formData.append('job', props.data.id)

                // Відправити файл
                const responseFile = await AxiosInstance.post(url, formData, header)

                // Повідомлення про успішне додавання файлу
                props.popup.toastifySuccess(popupTextAddFileSuccess)
            }

            // Отримати оновлені дані після завантаження файлу
            const responseUpdatedData = await AxiosInstance.get(`${urlApi}/${props.data.id}`, header)

            setArrFile([...responseUpdatedData.data.mediaObjects])
        } catch (error) {
            console.error('Сталася помилка під час обробки запиту', error)
        }
    }

    // console.log('arrFile', arrFile);

    // Функція для видалення доданих файлів
    const deleteFile = async (object, name) => {
        // console.log('object', object);
        // console.log('name', name);

        let dataToUpdate;

        if (name !== "additionalMaterials") {
            // Для protocole, graph, commandment
            dataToUpdate = {
                [name]: null,
                status: 'new',
            };
        }

        // console.log('dataToUpdate', dataToUpdate)

        try {
            await confirmAlert({
                title: 'Ви впевнені що хочите видалити цей пункт ?',
                message: 'Are you sure to do this.',
                buttons: [
                    {
                        label: 'Так',
                        onClick: async () => {
                            if (name === "additionalMaterials") {
                                await AxiosInstance.delete(`${urlMedia}/${object.id}`, header).then(() => {
                                    // console.log('файл видалено')
                                    // Повідомлення про успішне видалення файлу
                                    props.popup.toastifySuccess(popupTextDeleteFileSuccess)

                                    // Отримання оновлених даних після видалення файлу
                                    AxiosInstance.get(`${urlApi}/${props.data.id}`, header).then((response) => {
                                        if (response.status === 200) {
                                            setArrFile([...response.data.mediaObjects])
                                            setMyDataState(response.data)
                                        } else {
                                            console.log("Помилка оновлення даних");
                                        }
                                    })
                                })
                                    .catch((error) => {
                                        console.error('Помилка видалення:', error)
                                    })

                            } else {
                                await AxiosInstance.put(`${urlApi}/${dataState.id}`, dataToUpdate, header).then((response) => {
                                    // console.log('response', response);
                                    if (response.status === 200) {
                                        // Видалення файлу зображення
                                        AxiosInstance.delete(`${urlMedia}/${object.id}`, header)
                                            .then((response) => {
                                                // console.log('файл видалено')
                                            })
                                            .catch((error) => {
                                                console.error('Помилка видалення:', error)
                                            })
                                    }
                                })

                                // Повідомлення про успішне видалення файлу
                                props.popup.toastifySuccess(popupTextDeleteFileSuccess)
                                // Отримання оновлених даних після видалення файлу
                                const response = await AxiosInstance.get(`${urlApi}/${props.data.id}`, header)
                                // console.log('response3234242344', response.data)
                                setArrFile([...response.data.mediaObjects])
                                setMyDataState(response.data)
                            }
                        },
                    },
                    {
                        label: 'Ні',
                        onClick: () => {
                            return
                        },
                    },
                ],
            })
        } catch (error) {
            console.error('Помилка видалення:', error)
        }
    }


    const handleCheckboxChange = () => {
        setIsChecked(!isChecked)
    }


    // додаємо файл
    const handleAddFile = async (dataState, keyInObject, urlApi) => {
        try {
            // Створити URL для відправки файлу з фото
            const url = process.env.REACT_APP_SERVER_URL + '/api/media_objects'

            const response1 = await AxiosInstance.get(`${urlApi}/${props.data.id}`, header)
            // console.log('response1', response1.data[keyInObject].id)

            if (dataState) {
                const formData = new FormData()
                formData.append('file', dataState)
                formData.append('name', dataState.name)

                // Відправити файл у базу
                const responseAddFile = await AxiosInstance.post(url, formData, header)
                // console.log('responseAddFile', responseAddFile)

                // Зв'язуємо файл із URL
                if (responseAddFile.status === 201) {
                    const data_response = {
                        [keyInObject]: `/api/media_objects/${responseAddFile.data.id}`,
                    }

                    const responseAddNewFile = await AxiosInstance.patch(
                        `${urlApi}/${props.data.id}`,
                        data_response,
                        header,
                    )

                    if (response1.data[keyInObject]?.id) {
                        const deleteOldData = AxiosInstance.delete(
                            `${url}/${response1.data[keyInObject].id}`,
                            header,
                        )
                    }

                    // Повідомлення про успішне додавання файлу
                    props.popup.toastifySuccess(popupTextAddFileSuccess);

                    // Отримати оновлені дані після завантаження файлу
                    const responseUpdatedData = await AxiosInstance.get(`${urlApi}/${props.data?.id}`, header)
                    setMyDataState(responseUpdatedData.data)
                }
            }
        } catch (error) {
            console.error('Сталася помилка під час обробки запиту', error)
        }
    }

    // Функція для зміни статуса (вкладені документи)
    const changeStatus = async (newStatus) => {
        let data_response = {};
        if (newStatus === "checked") {
            data_response = {
                status: newStatus,
                comentar: "",
                assignedUser: `/api/users/${props.assignedUserId}`,
            };
        } else {
            data_response = {
                status: newStatus,
            };
        }

        try {
            await AxiosInstance.put(`${urlApi}/${props.data.id}`, data_response, header);
            // console.log('response', response);
            props.popup.toastifySuccess(popupTextDocumentSuccess);
            // Оновити дані
            getData();
        } catch (error) {
            console.error('Помилка під час виконання запиту', error);
        }
    };

    // Функція для зміни зауваження до доданих файлів (вкладені документи)
    const addComentar = async (comentar) => {
        const data_response = {
            comentar: comentar,
            status: "rejected"
        };
        try {
            await AxiosInstance.put(`${urlApi}/${props.data.id}`, data_response, header);
            // console.log('response', response);
            props.popup.toastifySuccess(popupTextAddComentarSuccess);
            getData();
        } catch (error) {
            console.error('Помилка під час виконання запиту', error);
        }
    };


    // Функція для закриття модального вікна з попередженням
    const handleCloseWarning = () => {
        // Закрити модальне з попередженням
        setOpenWarning(false);
    };

    return (
        <>
            {/* Модальне вікно з попередженням */}
            <WarningWhenChecked
                openWarning={openWarning}
                handleCloseWarning={handleCloseWarning}
                changeStatus={changeStatus}
            />

            {/* <CForm onSubmit={handleSubmitAddFile} className="mb-3"> */}


            {/* isDisabledAll={true} - Заблокувати можливу зміну */}
            {
                !isDisabledAll &&
                <>
                    {/* Блок кнопок */}
                    <ButtonsBlock
                        dataState={dataState} role={props.role}
                        isDisabledChange={isDisabledChange} isSendForInspection={isSendForInspection}
                        isDisabledSend={isDisabledSend}
                        changeStatus={changeStatus} addComentar={addComentar}
                        comentar={comentar}
                        setOpenWarning={setOpenWarning}
                    />

                    {/* Текстове поле з зауваженнями до файлів */}
                    <>
                        {
                            (
                                (dataState?.comentar !== undefined && dataState?.comentar !== "" && dataState?.status !== "synced" && dataState?.status !== "checked") ||
                                (props.role === "ROLE_ADMIN" && dataState?.status !== "checked" && dataState?.status !== "new" && dataState?.status !== "synced")
                            ) &&
                            <CFormTextarea
                                className='mt-3'
                                id="floatingTextarea"
                                floatingLabel="Зауваження від адміністратора"
                                placeholder="Leave a comment here"
                                // style={{ height: '100px' }}
                                value={comentar}
                                onChange={(e) => setComentar(e.target.value)}
                                disabled={(props.role !== "ROLE_ADMIN" || !(dataState?.status === "not_checked"))}
                            ></CFormTextarea>
                        }
                    </>

                    <hr />
                </>
            }


            {/* Загальні дані про роботу */}
            <JobData
                data={props.data}
                handleSubmit={props.handleSubmit}
                loadOptionsFactories={props.loadOptionsFactories}
                selectedOptionFactories={props.selectedOptionFactories}
                handleChangeFactories={props.handleChangeFactories}
                selectedOptionEngineers={props.selectedOptionEngineers}
                selectedEngineer={props.selectedEngineer}
                handleChangeEngineers={props.handleChangeEngineers}
                cancelChange={props.cancelChange}
                isDisabledAll={isDisabledAll}
                isDisabledChange={isDisabledChange}
            />

            <hr />

            <CForm onSubmit={handleSubmitAddFile} className="mb-3">
                {/* Працівники */}
                <div className="mb-5">
                    <JobWorkers
                        data={props.data}
                        getData={getData}
                        jobWorkers={jobWorkers}
                        popup={props.popup}
                        isDisabledAll={isDisabledAll}
                        isDisabledChange={isDisabledChange}
                        role={props.role}
                    />
                </div>


                <div className="mb-3">
                    <CFormCheck
                        id="flexCheckDefault"
                        label="Будуть виконуватись роботи підвищеної небезпеки"
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                        disabled={isDisabledAll || isDisabledChange}
                    />
                </div>

                {/* Роботи підвищеної небезпеки */}
                <div>
                    {
                        isChecked &&
                        <DangerWorks
                            data={props.data}
                            accJobsList={accJobsList}
                            getData={getData}
                            isDisabledAll={isDisabledAll}
                            isDisabledChange={isDisabledChange}
                            popup={props.popup}
                        />
                    }
                </div>

                <hr />

                {/* Про відповідального за Охорону праці */}
                <ResponsibleForLaborProtection
                    handleFileChangeState={handleFileChangeState}
                    setCommandmentState={setCommandmentState}
                    disabledChange={isDisabledChange}
                    handleAddFile={handleAddFile}
                    commandmentState={commandmentState}
                    urlApi={urlApi}
                    myDataState={myDataState}
                    css={props.css}
                    deleteFile={deleteFile}
                    isDisabledAll={isDisabledAll}
                />

                {/* Графік виконання робіт */}
                <WorkSchedule
                    handleFileChangeState={handleFileChangeState}
                    setGraphState={setGraphState}
                    disabledChange={isDisabledChange}
                    handleAddFile={handleAddFile}
                    graphState={graphState}
                    urlApi={urlApi}
                    myDataState={myDataState}
                    css={props.css}
                    deleteFile={deleteFile}
                    isDisabledAll={isDisabledAll}
                />

                {/* Протоколи перевірки знань з ОП */}
                <ProtocolOP
                    handleFileChangeState={handleFileChangeState}
                    setProtocoleState={setProtocoleState}
                    disabledChange={isDisabledChange}
                    handleAddFile={handleAddFile}
                    protocoleState={protocoleState}
                    urlApi={urlApi}
                    myDataState={myDataState}
                    css={props.css}
                    deleteFile={deleteFile}
                    isDisabledAll={isDisabledAll}
                />

                <hr />

                {/* Додаткові матеіали */}
                <AdditionalMaterials
                    handleFileChangeState={handleFileChangeState}
                    setFile={setFile} file={file}
                    css={props.css}
                    arrFile={arrFile}
                    deleteFile={deleteFile}
                    isDisabledAll={isDisabledAll}
                    isDisabledChange={isDisabledChange}
                />

                {/* isDisabledAll={true} - Заблокувати можливу зміну */}
                {
                    !isDisabledAll &&
                    <>
                        <hr />

                        {/* Блок кнопок */}
                        <ButtonsBlock
                            dataState={dataState} role={props.role}
                            isDisabledChange={isDisabledChange} isSendForInspection={isSendForInspection}
                            isDisabledSend={isDisabledSend}
                            changeStatus={changeStatus} addComentar={addComentar}
                            comentar={comentar}
                            setOpenWarning={setOpenWarning}
                        />

                    </>
                }

            </CForm >
        </>
    );
};

export default ExistJobForm;