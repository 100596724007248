import React, { useState } from 'react'
import {
  CCard,
  CCardBody,
  CCardHeader,
  CFormInput,
  CCol,
  CRow,
  CButton,
  CAccordion,
  CAccordionItem,
  CAccordionHeader,
  CAccordionBody,
  CFormLabel,
} from '@coreui/react'
import { DataGrid } from '@mui/x-data-grid'
import { Link, useLoaderData } from 'react-router-dom'
import AxiosInstance from 'src/components/Axios'
import { cilPen, cilPlus, cilTrash } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { useEffect } from 'react'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import AsyncSelect from 'react-select/async'

const page = 1
const urlApi_1 = '/account_jobs_lists'
const urlApi_2 = '/jobs'
const urlRouter = '/accounts'

// хто увійшов
const role = localStorage.getItem('user_role')
console.log(role)
// дані користувача
const userData = JSON.parse(localStorage.getItem('user_data'))

const tokenStr = localStorage.getItem('user')
const header = { headers: { Authorization: `Bearer ${tokenStr}`, accept: 'application/json' } }

// перше завантаження
export async function LoaderHome({ params }) {
  // const { page } = params;
  let query_1 = ''
  let query_2 = ''

  // питання запиту
  if (role === 'ROLE_PARTNER') {
    // query_1 += `&user.id=${userData.id}`
    query_1 += `&account.user.id=${userData.id}`

    // query_2 += `&user.id=${userData.id}`
    query_2 += `&account.user.id=${userData.id}`
  }

  //запит до сервера
  try {
    const response1 = await AxiosInstance.get(`${urlApi_1}?${query_1}`, header) //всі дозволи
    // console.log('response1', response1)

    const response2 = await AxiosInstance.get(`${urlApi_2}?${query_2}`, header) //всі роботи
    // console.log('response2', response2)

    return { data_1: response1.data, data_2: response2.data }
  } catch (error) {
    console.error('Помилка під час виконання запиту', error)
    return null
  }
}

// головний контент
const Workers = () => {
  // масиви даних
  const { data_1, data_2 } = useLoaderData() || {}
  // console.log('data_1', data_1)
  // console.log('data_2', data_2)
  const [dataState_1, setDataState_1] = useState(data_1)
  const [filterPermissionNotChecked, setFilterPermissionNotChecked] = useState(data_1)

  const [dataState_2, setDataState_2] = useState(data_2)
  const [filterJobNotChecked, setFilterJobNotChecked] = useState(data_2)

  useEffect(() => {
    setDataState_1(data_1 || [])
    setDataState_2(data_2 || [])
    // console.log('dataState_1', dataState_1);
    // console.log('dataState_2', dataState_2);
  }, [data_1, data_2])

  useEffect(() => {
    filterDateState_1()
    filterDateState_2()
  }, [dataState_1, dataState_2])

  const filterDateState_1 = () => {
    const newArr = dataState_1.filter((el) => el.status === 'not_checked')
    // console.log(newArr);
    setFilterPermissionNotChecked(newArr)
  }

  const filterDateState_2 = () => {
    const newArr = dataState_2.filter((el) => el.status === 'not_checked')
    setFilterJobNotChecked(newArr)
  }

  // console.log('кількысть дозволів',filterPermissionNotChecked.length);

  // console.log('role', role)

  return (
    <>
      {/* {role === 'ROLE_ADMIN' ?  */}
      <div className="d-flex text-align-center mb-3" style={{ fontWeight: 'bold' }}>
        <p>У вас {filterPermissionNotChecked.length} не підтверджені дозволи.</p>
        <Link to="/accounts" className="ms-3" state={{ status: 'not_checked' }}>
          <CButton type="submit" color="secondary">
            Переглянути
          </CButton>
        </Link>
      </div>
      {/* : ''} */}

      {/* {role === 'ROLE_ADMIN' &&  */}
      <div className="d-flex text-align-center" style={{ fontWeight: 'bold' }}>
        <p>У вас {filterJobNotChecked.length} не підтверджені роботи.</p>
        <Link to="/jobs" className="ms-3" state={{ status: 'not_checked' }}>
          <CButton type="submit" color="secondary">
            Переглянути
          </CButton>
        </Link>
      </div>
      {/* } */}
    </>
  )
}

export default Workers
