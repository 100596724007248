import React from 'react'

import Users, { LoaderUsers } from './views/users/Users'
import UsersForm, { LoaderUsersUpdate } from './views/users/UsersForm'

import Accounts, { LoaderAccounts } from './views/accounts/Accounts'
import AccountsForm, { LoaderAccountsUpdate } from './views/accounts/AccountsForm'
import Media, { LoaderMedia } from './views/media/Media';

import Home, { LoaderHome } from './views/home/Home'

import Workers, { LoaderWorkers } from './views/workers/Workers'
import WorkersForm, { LoaderWorkersUpdate } from './views/workers/WorkersForm'
import MyJobs, { LoaderMyJobs } from './views/myJobs/MyJobs'
import MyJobsForm, { LoaderMyJobsUpdate } from './views/myJobs/MyJobsForm'
import Faq, { LoaderFaq } from './views/faq/faq';
import FaqForm, { LoaderFaqUpdate } from './views/faq/faqForm';
import InstructionMedia, { LoaderInstructionMedia } from './views/instructionMedia/InstructionMedia'

// Notifications
const Login = React.lazy(() => import('./views/pages/login/Login'))

/** ASD ROUTERS */

const routes = [
  { path: '/', exact: true, name: 'Home', element: <Home />, loader: LoaderHome },
  { path: '/login', name: 'Логін', element: <Login /> },

  { path: '/accounts', name: 'Контрагенти', element: <Accounts />, loader: LoaderAccounts },
  {
    path: '/accounts/create',
    name: 'Створити контрагента',
    element: <AccountsForm />,
    loader: LoaderAccountsUpdate,
  },
  {
    path: '/accounts/update/:pid',
    name: 'Редагувати контрагента',
    element: <AccountsForm />,
    loader: LoaderAccountsUpdate,
  },

  { path: '/workers', name: 'Працівники', element: <Workers />, loader: LoaderWorkers },
  {
    path: '/workers/create',
    name: 'Додати працівника',
    element: <WorkersForm />,
    loader: LoaderWorkersUpdate,
  },
  {
    path: '/workers/update/:pid',
    name: 'Редагувати працівника',
    element: <WorkersForm />,
    loader: LoaderWorkersUpdate,
  },

  { path: '/users', name: 'Користувачі', element: <Users />, loader: LoaderUsers },
  {
    path: '/users/create',
    name: 'Створити користувача',
    element: <UsersForm />,
    loader: LoaderUsersUpdate,
  },
  {
    path: '/users/update/:pid',
    name: 'Редагувати користувача',
    element: <UsersForm />,
    loader: LoaderUsersUpdate,
  },

  { path: '/jobs', name: 'Роботи', element: <MyJobs />, loader: LoaderMyJobs },
  {
    path: '/jobs/create',
    name: 'Створити роботу',
    element: <MyJobsForm />,
    loader: LoaderMyJobsUpdate,
  },
  {
    path: '/jobs/update/:pid',
    name: 'Редагувати користувача',
    element: <MyJobsForm />,
    loader: LoaderMyJobsUpdate,
  },

  // { path: '/faq', name: 'FAQ', element: <Faq />, loader: LoaderFaq },
  // { path: '/faq/create', name: 'Створити FAQ', element: <FaqForm />, loader: LoaderFaqUpdate },
  // { path: '/faq/update/:pid', name: 'Редагувати FAQ', element: <FaqForm />, loader: LoaderFaqUpdate },


  { path: '/instruction', name: 'Інструкція', element: <InstructionMedia />, loader: LoaderInstructionMedia },

]

export default routes
