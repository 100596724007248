import React, { useState } from 'react'
import {
  CCard,
  CCardBody,
  CCardHeader,
  CFormInput,
  CCol,
  CRow,
  CButton,
  CAccordion,
  CAccordionItem,
  CAccordionHeader,
  CAccordionBody,
  CFormLabel,
} from '@coreui/react'
import { DataGrid } from '@mui/x-data-grid'
import { Link, useLoaderData } from 'react-router-dom'
import AxiosInstance from 'src/components/Axios'
import { cilPen, cilPlus, cilTrash } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { useEffect } from 'react'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import AsyncSelect from 'react-select/async'

const page = 1
const urlApi = '/workers'
const urlRouter = '/workers'

// хто увійшов
const role = localStorage.getItem('user_role')
// дані користувача
const userData = JSON.parse(localStorage.getItem('user_data'))

const tokenStr = localStorage.getItem('user')
const header = { headers: { Authorization: `Bearer ${tokenStr}`, accept: 'application/json' } }

// перший завантаження
export async function LoaderWorkers({ params }) {
  let query = `page=${page}`

  if (role === 'ROLE_PARTNER') query += `&accountWorkers.account.user.id=${userData.id}`

  let res = await AxiosInstance.get(`${urlApi}?${query}`).then((response) => {
    return response
  })
  return res
}

// головний контент
const Workers = () => {
  const { data } = useLoaderData()

  const [rows, setRows] = useState(false)
  const [rowCountState, setRowCountState] = useState(0)
  const [nameFilterSearch, setNameFilterSearch] = useState('')
  const [ipnFilterSearch, setIpnFilterSearch] = useState('')

  const [managerFirstNameFilterSearch, setManagerFirstNameFilterSearch] = useState('')
  const [partnerFilterSearch, setPartnerFilterSearch] = useState('')
  // Заблокувати кнопку фільрації якщо не введені символи для пошуку
  const [disabled, setDisabled] = useState(true)


  // useEffect(() => {
  //   console.log('partnerFilterSearch', partnerFilterSearch);
  // }, [partnerFilterSearch])

  // перевірка чи є масив даних
  if (!rows) {
    setRows(data['hydra:member']) //масив
    setRowCountState(data['hydra:totalItems']) //кількість
  }

  // Фільтр
  const getRowsTable = (p = 1) => {
    let request = ''

    // перевірка чи є щось в імені
    if (nameFilterSearch !== '') {
      request += `&fullName=${nameFilterSearch}`
    }

    if (ipnFilterSearch !== '') {
      request += `&codeIpn=${ipnFilterSearch}`
    }

    // якщо вибрананий контрагент
    if (partnerFilterSearch !== '')
      request += `&accountWorkers.account.id=${partnerFilterSearch.id}`

    if (role !== 'ROLE_ADMIN') {
      request += `&accountWorkers.account.user.id=${userData.id}`
    }

    // запит після пошуку
    AxiosInstance.get(`${urlApi}?page=${p}${request}`).then((response) => {
      // console.log('response', response);
      setRows(response.data['hydra:member'])
      setRowCountState(response.data['hydra:totalItems'])
    })
  }

  // зміна сторінки
  const handlePageChange = (page) => {
    getRowsTable(page + 1)
  }

  // Заблокувати кнопку фільтрації, якщо не введені символи для пошуку
  const disabledButton = () => {
    setDisabled(
      typeof partnerFilterSearch === 'string' &&
      partnerFilterSearch.trim() === '' &&
      ipnFilterSearch.trim() === '' &&
      nameFilterSearch.trim() === '' &&
      managerFirstNameFilterSearch.trim() === '',
    )
  }

  useEffect(() => {
    // Розблокувати кнопку, якщо хоча б одне поле містить значення
    disabledButton()
  }, [nameFilterSearch, managerFirstNameFilterSearch, partnerFilterSearch])

  // зміна стейту пошуку
  const handleNameFilterChange = (event) => {
    setNameFilterSearch(event.target.value)
    disabledButton(event)
  }

  const handleIpnFilterChange = (event) => {
    setIpnFilterSearch(event.target.value)
    disabledButton(event)
  }

  // Фільтрування
  const handleFilterSearch = () => {
    getRowsTable()
  }

  // Скинути фільтр
  const handleFilterReset = () => {
    setNameFilterSearch('')
    setPartnerFilterSearch('')
    setDisabled(true)
    getRowsTable()
  }

  async function deleted(row) {
    try {
      await confirmAlert({
        title: 'Ви впевнені що хочите видалити цей пункт ?',
        message: 'Are you sure to do this.',
        buttons: [
          {
            label: 'Так',
            onClick: async () => {
              const response = await AxiosInstance.delete(`${urlApi}/${row.id}`)

              console.log('Пункт успішно видалено.', response)

              getRowsTable()
            },
          },
          {
            label: 'Ні',
            onClick: () => {
              return
            },
          },
        ],
      })
    } catch (error) {
      console.error('Помилка видалення:', error)
    }
  }

  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    {
      field: 'fullName',
      headerName: 'ПІБ',
      width: 250,
      renderCell: (params) => {
        return (
          <Link
            to={`${urlRouter}/update/${params.row.id}`}
            // style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)' }}
            style={{ textDecoration: 'none' }}
          >
            {params.row.fullName}
          </Link>
        )
      },
    },

    { field: 'codeIpn', headerName: 'ІПН', width: 200 },

    {
      field: 'action',
      headerName: 'Редагувати',
      sortable: false,
      renderCell: (params) => {
        return (
          <Link to={`${urlRouter}/update/${params.row.id}`}>
            <CButton color="dark" variant="outline">
              <CIcon icon={cilPen} customClassName="nav-icon" height={20} />
            </CButton>
          </Link>
        )
      },
    },
    {
      field: 'delete',
      headerName: 'Видалити',
      sortable: false,
      renderCell: (params) => {
        return (
          <CButton
            color="danger"
            variant="outline"
            onClick={() => {
              deleted(params.row)
            }}
          >
            <CIcon icon={cilTrash} customClassName="nav-icon" height={20} />
          </CButton>
        )
      },
    },
  ]

  // Натиснення кнопки: "Enter" - виконати пошук; "Escape" - скинути пошук
  const keyDown = (event) => {
    // Виконати пошук
    if (event.key === 'Enter' && !disabled) {
      event.preventDefault()
      handleFilterSearch(event)
    }
    // Скинути пошук
    if (event.key === 'Escape') {
      event.preventDefault()
      handleFilterReset(event)
    }
  }

  const loadOptions = async (inputValue) => {
    if (role === 'ROLE_ADMIN') {

      return await AxiosInstance.get(`/accounts`, header).then((response) => {

        return response.data.map((result) => ({
          label: result.name,
          id: result.id,
        }))
      })
    } else {
      if (userData.id) {
        return await AxiosInstance.get(`/users/${userData.id}`, header).then((response) => {

          return response.data.accounts.map((result) => ({
            // description: description,
            label: result.name,
            id: result.id,
          }))
        })
      }
    }
  }

  // при зміні опції
  const handleChangePartner = (select) => {
    setPartnerFilterSearch(select)
  }

  return (
    <CRow>
      <CCol xs={12}>
        <CCard className="mb-4">
          <CAccordion flush>
            <CAccordionItem>
              <CAccordionHeader onClick={loadOptions}>
                <strong>Фільтр</strong>
              </CAccordionHeader>
              <CAccordionBody>
                <CRow className="my-3 mx-2">
                  <CCol md={9} className="me-auto">
                    <CRow>
                      <CCol md={4} className="filter-field">
                        <CFormInput
                          placeholder="Пошук за ПІБ"
                          id="nameFilterSearch"
                          aria-label="Пошук"
                          className="me-2"
                          onChange={handleNameFilterChange}
                          value={nameFilterSearch}
                          onKeyDown={keyDown}
                          autoComplete="off"
                        />
                      </CCol>

                      <CCol md={4} className="filter-field">
                        <CFormInput
                          placeholder="Пошук за ІПН"
                          id="ipnFilterSearch"
                          aria-label="Пошук"
                          className="me-2"
                          onChange={handleIpnFilterChange}
                          value={ipnFilterSearch}
                          onKeyDown={keyDown}
                          autoComplete="off"
                        />
                      </CCol>

                      <CCol md={4} className="filter-field">
                        <AsyncSelect
                          cacheOptions
                          loadOptions={loadOptions}
                          defaultOptions
                          value={partnerFilterSearch}
                          onChange={handleChangePartner}
                          className="flex-grow-1"
                          placeholder="Контрагент"
                        />
                      </CCol>
                    </CRow>
                  </CCol>
                  <CCol md={3} style={{ textAlign: 'end' }}>
                    <CButton
                      className="me-2"
                      type="submit"
                      color="secondary"
                      onClick={handleFilterSearch}
                      disabled={disabled}
                    >
                      Пошук
                    </CButton>
                    <CButton type="submit" color="secondary" onClick={handleFilterReset}>
                      X
                    </CButton>
                  </CCol>
                </CRow>
              </CAccordionBody>
            </CAccordionItem>
          </CAccordion>

          <CCardHeader>
            <strong>Працівники</strong> <small></small>
          </CCardHeader>
          <CCardBody>
            <p className="text-medium-emphasis small">
              <Link to={`${urlRouter}/create`}>
                <CButton color="secondary">
                  <CIcon icon={cilPlus} customClassName="nav-icon" height={15} />
                  Створити
                </CButton>
              </Link>
            </p>

            <div style={{ height: 400, width: '100%' }}>
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={30}
                rowsPerPageOptions={[30]}
                pagination
                rowCount={rowCountState}
                paginationMode="server"
                onPageChange={handlePageChange}
              />
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  )
}

export default Workers
