import React, { useEffect, useRef, useState } from 'react'
import { Editor } from '@tinymce/tinymce-react'
import { Link, useLoaderData } from 'react-router-dom'
import AxiosInstance from 'src/components/Axios'
import { useNavigate } from 'react-router-dom'
import { CForm, CFormLabel, CFormInput, CButton, CListGroup, CListGroupItem } from '@coreui/react'
import AsyncSelect from 'react-select/async'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import MyToastify from 'src/components/myComponents/MyToactify'
import Select from 'react-select'
import { confirmAlert } from 'react-confirm-alert'
import { cilCheckAlt, cilTrash } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { DataGrid } from '@mui/x-data-grid'

const urlApi = '/accounts'
const urlApi_2 = '/account_jobs_lists'
const urlRouter = '/accounts'
const tokenStr = localStorage.getItem('user')
const header = { headers: { Authorization: `Bearer ${tokenStr}`, accept: 'application/json' } }
// хто увійшов
const role = localStorage.getItem('user_role')
// console.log(role)

export async function LoaderAccountsUpdate({ params }) {
  const id = params.pid
  let data2 = null

  try {
    const response1 = await AxiosInstance.get(`${urlApi}/${id}`, header)
    // console.log('response1', response1.data);

    try {
      const response2 = await AxiosInstance.get(`${urlApi_2}?account.id=${id}`, header)
      // console.log('response2', response2);
      data2 = response2.data
    } catch (error) {
      console.error('Помилка при другому запиті', error)
      // Тут ви можете обробити помилку від другого запиту, якщо потрібно.
    }

    return { data: response1.data, data2 }
  } catch (error) {
    console.error('Помилка при першому запиті', error)
    return null
  }
}

const AccountsForm = () => {
  const navigate = useNavigate()
  const { data, data2 } = useLoaderData() || {}

  //  console.log('акаунт форм data ', data);
  //  console.log('акаунт форм data2 ', data2);
  //  console.log('акаунт форм data2 ', data2);

  const [name, setName] = useState(data != undefined ? data.name : '')
  const [description, setDescription] = useState(data != undefined ? data.description : '')
  // const [edrpou, setEdrpou] = useState((data != undefined) ? data.edrpou : '');
  const [phone, setPhone] = useState(data != undefined ? data.phone : '')
  const [user, setUser] = useState('')
  const [manager, setManager] = useState('')
  const [selectedOption, setSelectedOption] = useState(null)
  const [selectedOptionType, setSelectedOptionType] = useState(null)
  const [disabledInput, setDisabledInput] = useState(true)
  const [dateEnd, setdateEnd] = useState('')
  const [showFormAdd, setShowFormAdd] = useState(false)

  const [typeWorkList, setTypeWorkList] = useState(Array.isArray(data2) ? [...data2] : [])
  const [file, setFile] = useState(null)
  const [idNewFile, setIdNewFile] = useState(null)
  const [arrWithFile, setArrWithFile] = useState([])

  // useEffect(() => {
  //   console.log('idNewFile', idNewFile)
  // }, [idNewFile])

  // Викликає popup з власного компонента
  const popup = MyToastify()
  // Текст для popup
  const popupTextPostSuccess = 'Контрагента створено'
  const popupTextPutSuccess = 'Контрагента оновлено'
  const popupTextPathSuccess = 'Документи прийняті'
  const popupTextDeleteSuccess = 'Файл видалено'

  useEffect(() => {
    role === 'ROLE_PARTNER' ? setDisabledInput(true) : setDisabledInput(false)
  }, [role])

  const getUserName = (user) => {
    let fn = user.firstName !== undefined ? `${user.firstName} ` : ''
    let ln = user.lastName !== undefined ? `${user.lastName} ` : ''
    let un = user.username !== undefined ? ` (${user.username})` : ''
    return `${fn}${ln}${un}`
  }

  if (data !== undefined && data.user !== undefined && user === '') {
    setUser(`/api/users/${data.user.id}`)
    setSelectedOption({
      label: getUserName(data.user),
      value: data.user.id,
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    const data_response = {
      name: name,
      description: description,
      phone: phone,
      manager: manager,
      user: user,
      active: 'on',
    }
    if (data != undefined) {
      const response = AxiosInstance.put(`${urlApi}/${data.id}`, data_response, header).then(
        (response) => {
          if (response.status === 200) {
            // Popup про успішні зміни
            popup.toastifySuccess(popupTextPutSuccess)
            return navigate(urlRouter)
          }
        },
      )
    } else {
      const response = AxiosInstance.post(`${urlApi}`, data_response, header).then((response) => {
        if (response.status === 201) {
          // Popup про успішні зміни
          popup.toastifySuccess(popupTextPostSuccess)
          return navigate(urlRouter)
        }
      })
    }
  }

  const loadOptions = async (inputValue) => {

    try {
      const inputValue1 = inputValue.toLowerCase()

      // const limit = 100; // Кількість користувачів для вибору
      // const response = await AxiosInstance.get(`/users?itemsPerPage=${limit}`, {
      const response = await AxiosInstance.get(`/users?pagination=false`, {
        headers: { accept: 'application/json' },
      });


      console.log(response);

      // костиль з пошуком

      const newArrAboutFilter = response.data.filter(
        (el) =>
          (el.firstName && el.firstName.toLowerCase().includes(inputValue1)) ||
          (el.lastName && el.lastName.toLowerCase().includes(inputValue1)) ||
          (el.username && el.username.toLowerCase().includes(inputValue1)),
      )

      // console.log('newArrAboutFilter', newArrAboutFilter);

      return newArrAboutFilter.map((result) => ({
        //відображаю масив
        // return response.data.map((result) => ({ //відображаю масив
        label: getUserName(result),
        // label: result.username,
        value: result.id,
      }))
    } catch (error) {
      console.error('Помилка при завантаженні опцій:', error)
      return []
    }
  }

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption)
    setUser(`/api/users/${selectedOption.value}`)
  }

  const handleChangeType = (selectedOption) => {
    setSelectedOptionType(selectedOption)
    // setUser(`/api/users/${selectedOption.value}`)
  }

  const handleAdd = () => {
    // console.log('я клікнув додати дозвіл')
    setShowFormAdd(!showFormAdd)
    // console.log(showFormAdd);
  }

  // const handleSubmitForInspection = async () => {
  //   // console.log('Відправив файл на перевірку')

  //   handleSubmitAddFile()

  //   const data_response = {
  //     // name: typeWork,
  //     name: selectedOptionType.label,
  //     status: 'not_checked',
  //     document: `/api/media_objects/${idNewFile}`,
  //     account: `/api/accounts/${data.id}`,
  //     dateEnd: dateEnd,
  //   }

  //   try {
  //     const response = await AxiosInstance.post(`${urlApi_2}`, data_response, header)

  //     console.log(123123)

  //     popup.toastifySuccess(popupTextPutSuccess)

  //     // Отримання оновлених даних після відправки файлу
  //     const response3 = await AxiosInstance.get(`${urlApi_2}?account.id=${data.id}`, header)
  //     // console.log('response3', response3)
  //     setTypeWorkList(response3.data)
  //   } catch (error) {
  //     console.error('Помилка під час відправки даних:', error)
  //   }
  // }

  const handleSubmitForInspection = async () => {
    try {
      const url = process.env.REACT_APP_SERVER_URL + '/api/media_objects'

      let fileId

      if (file) {
        const formData = new FormData()
        formData.append('file', file)
        formData.append('name', file.name)

        const responseFile = await AxiosInstance.post(url, formData, header)

        fileId = responseFile.data.id
        setIdNewFile(fileId)
      }

      const data_response = {
        name: selectedOptionType.label,
        status: 'not_checked',
        // document: `/api/media_objects/${idNewFile}`,
        document: `/api/media_objects/${fileId}`,
        account: `/api/accounts/${data.id}`,
        dateEnd: dateEnd,
      }

      console.log('data_response', data_response)

      const response = await AxiosInstance.post(`${urlApi_2}`, data_response, header)

      console.log(123123)

      popup.toastifySuccess(popupTextPutSuccess)

      const response3 = await AxiosInstance.get(`${urlApi_2}?account.id=${data.id}`, header)
      setTypeWorkList(response3.data)
    } catch (error) {
      console.error('Помилка під час відправки даних:', error)
    }
  }

  const hendlDeleteTypeJobs = async (id) => {
    try {
      await confirmAlert({
        title: 'Ви впевнені що хочите видалити цей пункт ?',
        message: 'Are you sure to do this.',
        buttons: [
          {
            label: 'Так',
            onClick: async () => {
              // Видалення файлу
              await AxiosInstance.delete(`${urlApi_2}/${id}`, header)
              popup.toastifySuccess(popupTextDeleteSuccess)

              // Отримання оновлених даних після видалення файлу
              const response3 = await AxiosInstance.get(`${urlApi_2}?account.id=${data.id}`, header)

              // console.log('response3', response3)
              setTypeWorkList([...response3.data])
            },
          },
          {
            label: 'Ні',
            onClick: () => {
              return
            },
          },
        ],
      })
    } catch (error) {
      console.error('Помилка видалення:', error)
    }
  }

  const formatDateFromISO = (isoDateString) => {
    const dateParts = isoDateString.split('T')[0].split('-')
    const year = dateParts[0]
    const month = dateParts[1]
    const day = dateParts[2]
    return `${day}/${month}/${year}`
  }

  const addFileToArrType = async () => {
    try {
      const newArrWithFile = await Promise.all(
        typeWorkList.map(async (el) => {
          if (el.document) {
            const path = el.document
            const parts = path.split('/')
            const id = parts[parts.length - 1]

            try {
              const response = await AxiosInstance.get(`media_objects/${id}`)
              return {
                ...el,
                contentUrl: response.data.contentUrl,
              }
            } catch (error) {
              console.error('Помилка під час виконання запитів', error)
              return null
            }
          } else {
            return el
          }
        }),
      )

      setArrWithFile(newArrWithFile)
    } catch (error) {
      console.error('Помилка при виклику addFileToArrType', error)
    }
  }

  useEffect(() => {
    addFileToArrType()
  }, [typeWorkList])

  // console.log(123, arrWithFile)

  // додавання файлів
  const handleFileChange = (event) => {
    const myFile = event.target.files[0]

    setFile(myFile)
  }

  // відправити файл
  const handleSubmitAddFile = async (e) => {
    // console.log('роблю запит відправити файл')

    try {
      const url = process.env.REACT_APP_SERVER_URL + '/api/media_objects'

      if (file) {
        const formData = new FormData()
        formData.append('file', file)
        formData.append('name', file.name)

        const responseFile = await AxiosInstance.post(url, formData, header)

        const fileId = responseFile.data.id
        setIdNewFile(fileId)
      }
    } catch (error) {
      console.error('Сталася помилка під час обробки запиту', error)
      throw error // Додайте цей рядок для переходу до блоку catch у викликаючій функції
    }

    // try {
    //   const url = process.env.REACT_APP_SERVER_URL + '/api/media_objects'

    //   if (file) {
    //     const formData = new FormData()
    //     formData.append('file', file)
    //     formData.append('name', file.name)

    //     const responseFile = await AxiosInstance.post(url, formData, header)

    //     // Отримуємо ідентифікатор файлу з відповіді сервера
    //     const fileId = responseFile.data.id
    //     // console.log('ID створеного файлу:', fileId)

    //     setIdNewFile(fileId)
    //   }
    // } catch (error) {
    //   console.error('Сталася помилка під час обробки запиту', error)
    // }
  }

  const handlClickAdminConfirm = async (id) => {
    // console.log('клік перевірено')

    const data_response = {
      status: 'checked',
    }

    try {
      const response = await AxiosInstance.patch(`${urlApi_2}/${id}`, data_response, header)

      popup.toastifySuccess(popupTextPutSuccess)

      // Отримання оновлених даних після відправки файлу
      const response3 = await AxiosInstance.get(`${urlApi_2}?account.id=${data.id}`, header)
      // console.log('response3', response3)
      setTypeWorkList(response3.data)
    } catch (error) {
      console.error('Помилка під час відправки даних:', error)
    }
  }

  const dateСomparison = (date) => {
    // функція приймає дату і порівнює її до існуючої
    const myDate = new Date(date)
    const curentDate = new Date()

    const myTimeInMilliseconds = myDate.getTime()
    const curentTimeInMilliseconds = curentDate.getTime()

    // повертаємо true якщо дата менша за істуючу
    return myTimeInMilliseconds < curentTimeInMilliseconds
  }

  // завантаження опцій підрядників у форму
  const loadOptionsType = async (inputValue) => {
    return await AxiosInstance.get(`/jobs_libs/?itemsPerPage=100`, header).then((response) => {
      // console.log(response.data);
      return response.data.map((result) => ({
        label: result.name,
        id: result.id,
      }))
    })
  }

  return (
    <>
      <ToastContainer />

      <CForm onSubmit={handleSubmit} className="mb-3">
        <div className="mb-3">
          <CFormLabel disabled={disabledInput} htmlFor="exampleFormControlInput1">
            Назва
          </CFormLabel>
          <CFormInput
            disabled={disabledInput}
            type="text"
            id="exampleFormControlInput1"
            value={name}
            onChange={(e) => setName(e.target.value)}
            autoComplete="off"
          />
        </div>
        <div className="mb-3">
          <CFormLabel disabled={disabledInput} htmlFor="exampleFormControlInput1">
            Користувач
          </CFormLabel>

          <AsyncSelect
            isDisabled={disabledInput}
            cacheOptions
            loadOptions={loadOptions}
            defaultOptions
            value={selectedOption}
            onChange={handleChange}
            className="flex-grow-1"
          />
        </div>

        <div className="mb-3">
          <CFormLabel disabled={disabledInput} htmlFor="exampleFormControlInput2">
            Коментар
          </CFormLabel>
          <CFormInput
            disabled={disabledInput}
            type="text"
            id="exampleFormControlInput2"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            autoComplete="off"
          />
        </div>
        <div className="mb-3">
          <CFormLabel disabled={disabledInput} htmlFor="exampleFormControlInput4">
            Телефон
          </CFormLabel>
          <CFormInput
            disabled={disabledInput}
            type="text"
            id="exampleFormControlInput4"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            autoComplete="off"
          />
        </div>

        {role === 'ROLE_ADMIN' ? (
          <>
            <CButton type="submit" color="success">
              Зберегти
            </CButton>
            <Link to={urlRouter} className="ms-3">
              <CButton type="submit" color="secondary">
                Відміна
              </CButton>
            </Link>
          </>
        ) : null}
        <hr />

        <div>
          <p className="h4">Керування роботами</p>

          <div className="col d-flex justify-content-end">
            <CButton onClick={handleAdd} className="mb-2" color="secondary">
              Додати дозвіл на роботу
            </CButton>
          </div>

          {/* <CFormLabel htmlFor="exampleFormControlInput1">Перелік робіт</CFormLabel> */}

          <CListGroup>
            {showFormAdd && (
              <CListGroupItem className="d-flex justify-content-between align-items-center">
                <div style={{ width: '400px', marginRight: '100px' }}>
                  <CFormLabel htmlFor="exampleFormControlInput2">Дозвіл</CFormLabel>

                  <AsyncSelect
                    cacheOptions
                    loadOptions={loadOptionsType}
                    defaultOptions
                    value={selectedOptionType}
                    onChange={handleChangeType}
                    className="flex-grow-1"
                  />
                </div>

                <div style={{ marginRight: '100px' }}>
                  <CFormLabel htmlFor="exampleFormControlInput2">Дозвіл до</CFormLabel>
                  <CFormInput
                    type="date"
                    id="exampleFormControlInput2"
                    value={dateEnd}
                    onChange={(e) => setdateEnd(e.target.value)}
                    autoComplete="off"
                  />
                </div>

                <div style={{ marginRight: '100px', width: '550px' }}>
                  <CFormLabel htmlFor="exampleFormControlInput2">Документ</CFormLabel>

                  <div className="d-flex justify-content-between align-items-center">
                    <CFormInput
                      type="file"
                      id="exampleFormControlFile1"
                      onChange={handleFileChange}
                      multiple
                    />

                    {/* <CButton
                      color="primary"
                      variant="outline"
                      onClick={() => handleSubmitAddFile()}
                    >
                      +
                    </CButton> */}
                  </div>
                </div>

                <CButton
                  // disabled={!file ? true : false}
                  color="success"
                  variant="outline"
                  onClick={() => handleSubmitForInspection()}
                // onClick={() => handleClick()}
                >
                  Відправити
                </CButton>
              </CListGroupItem>
            )}

            <hr />

            <p className="h4 mb-5">Стан дозволів</p>

            {arrWithFile &&
              arrWithFile.map((el, i) => (
                <CListGroupItem
                  className="d-flex justify-content-between align-items-center"
                  key={i}
                >
                  <div className="d-flex justify-content-between align-items-center  w-100">
                    <div
                      className=""
                      style={{ marginRight: '100px', minWidth: '50%', maxWidth: '50%' }}
                    >
                      {/* <CFormInput type="text" value={el.name} disabled={true} /> */}
                      <p>{el.name} 1</p>
                    </div>

                    <p style={{ maxWidth: '100px', minWidth: '100px' }}>
                      Дозвіл до{' '}
                      <span
                        style={{
                          color: dateСomparison(el.dateEnd) ? 'red' : 'black',
                          minWidth: '120px',
                          marginRight: '100px',
                        }}
                      >
                        {formatDateFromISO(el.dateEnd)}
                      </span>
                    </p>

                    <div style={{ margin: '0 25px' }}>
                      {el.contentUrl ? (
                        <div>
                          <a
                            href={process.env.REACT_APP_SERVER_URL + el.contentUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Документ
                            {/* {el.contentUrl} */}
                          </a>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>

                    <div
                      className="d-flex justify-content-between align-items-center"
                      style={{ width: '200px' }}
                    >
                      {role === 'ROLE_PARTNER' && el.status === 'not_checked' ? (
                        <p>Очікує перевірки</p>
                      ) : (
                        ''
                      )}

                      {role === 'ROLE_PARTNER' && el.status === 'checked' ? (
                        <p style={{ width: '150px' }}>Прийнято</p>
                      ) : (
                        ''
                      )}

                      {role === 'ROLE_ADMIN' && el.status === 'not_checked' ? (
                        <CButton
                          color="success"
                          variant="outline"
                          style={{ fontSize: '14px', width: '150px', padding: '0' }}
                          onClick={() => handlClickAdminConfirm(el.id)}
                        >
                          Підтвердити
                          <CIcon icon={cilCheckAlt} customClassName="nav-icon" height={15} />
                        </CButton>
                      ) : (
                        ''
                      )}

                      {role === 'ROLE_ADMIN' && el.status === 'checked' ? <p>Підтверджено</p> : ''}

                      {role === 'ROLE_ADMIN' ? (
                        <CButton
                          color="danger"
                          variant="outline"
                          onClick={() => hendlDeleteTypeJobs(el.id)}
                          style={{ marginLeft: '50px' }}
                        >
                          <CIcon icon={cilTrash} customClassName="nav-icon" height={20} />
                        </CButton>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </CListGroupItem>
              ))}
          </CListGroup>
        </div>
      </CForm>
    </>
  )
}

export default AccountsForm
