import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { CForm, CFormLabel, CFormInput, CButton, CListGroup, CListGroupItem, CFormCheck } from '@coreui/react';
import AxiosInstance from 'src/components/Axios';

// Компонент для робіт підвищеної небезпеки
const DangerWorks = (props) => {
    // console.log('props DangerWorks', props);
    const [jobsList, setJobsList] = useState([])
    const [job, setJob] = useState([])
    // Дата закінчення роботи
    // const [dateEnd, setDateEnd] = useState("2023-12-15T00:00:00+00:00");
    const [dateEnd, setDateEnd] = useState(props.data.dateEnd != undefined ? props.data.dateEnd : "");
    const [filteredJobsList, setFilteredJobsList] = useState([])
    const popupTextAddJobSuccess = "Роботу додано";
    const popupTextDeleteJobSuccess = "Роботу видалено";

    useEffect(() => {
        getJobsList()
    }, []);

    // Запит за одиницями виміру
    const getJobsList = async (inputValue) => {
        await AxiosInstance.get(`/account_jobs_lists?account.id=${props.data?.account.id}`).then((response) => {
            // console.log('response', response);
            if (response.status === 200) {
                setJobsList(response.data['hydra:member']);
            } else {
                console.log('Помилка завантаження');
            }
        });
    };

    // Додавання типу роботи в масиву
    const handleSave2 = () => {
        // console.log('клік додати дозвіл', accJobsList);
        const newArrString = props.accJobsList.map((el) => `/api/account_jobs_lists/${el.id}`)

        // console.log('newArrString', newArrString);
        // оновлення
        AxiosInstance.patch(`/jobs/${props.data.id}`, {
            AccountJobsList: [...newArrString, `/api/account_jobs_lists/${job.value}`], // додавання вибраних дозволів
        })
            .then((response) => {
                // console.log('res2', response);
                props.getData()
                // Повідомлення про успішне додавання роботи
                props.popup.toastifySuccess(popupTextAddJobSuccess)
            })
            .catch((error) => {
                console.error('Error in handleSave2:', error)
            })
    }

    // Відфільтрувати роботи, термін дії яких не закінчується до дати завершення роботи
    useEffect(() => {
        if (dateEnd != undefined && jobsList.length > 0) {
            // console.log('jobsList.length', jobsList.length);
            // console.log("Кінцева дата", new Date(dateEnd));

            // Масив з відфільтрованими по даті роботами та всіма даними про роботи
            let list = [];
            list = jobsList.filter((job) => new Date(job.dateEnd) > new Date(dateEnd));
            // console.log('list', list);

            // Масив з відфільтрованими по даті роботами підготований для виводу в Select
            let newList = list.map((job) =>
                job.status === 'checked'
                    ? {
                        label: job.name,
                        value: job.id,
                    }
                    : {},
            )
            // console.log('newList', newList);

            setFilteredJobsList(newList);
        }
    }, [dateEnd, jobsList]);


    const formatDateFromISO = (isoDateString) => {
        const dateParts = isoDateString.split('T')[0].split('-')
        const year = dateParts[0]
        const month = dateParts[1]
        const day = dateParts[2]
        return `${day}/${month}/${year}`
    }

    // Видалення типу роботи з масиву
    const delElWithArr = (elId) => {
        // console.log('кдік видалити дозвіл', accJobsList);

        // створення масив обєктів без вибраного елементу
        const newArrwithotElId = props.accJobsList.filter((el) => el.id !== elId)
        // console.log('newArrwithotElId', newArrwithotElId);
        const newArrString = newArrwithotElId.map((el) => `/api/account_jobs_lists/${el.id}`)

        // оновлення
        AxiosInstance.patch(`/jobs/${props.data.id}`, {
            AccountJobsList: [...newArrString], // додавання вибраних дозволів
        })
            .then((response) => {
                // console.log('res2', response)
                props.getData()
                // Повідомлення про успішне видалення роботи
                props.popup.toastifySuccess(popupTextDeleteJobSuccess)
            })
            .catch((error) => {
                console.error('Error in handleSave2:', error)
            })
    }

    return (
        <div className="mb-5">
            <p className="h4">Роботи підвищеної небезпеки</p>

            {/* props.isDisabledAll={true} - Заблокувати можливу зміну, оскільки дані вже вневені в 1С */}
            {
                !props.isDisabledAll &&
                <div className="col d-flex justify-content-end">
                    {/* <span>Додати працівника </span> */}
                    <Select
                        options={filteredJobsList}
                        value={job}
                        onChange={(job) => setJob(job)}
                        className="w-25"
                        isDisabled={props.isDisabledChange}
                    />
                    <CButton onClick={handleSave2} className="ms-3 mb-2" color="secondary" disabled={job.length == 0 || props.isDisabledChange}>
                        +
                    </CButton>
                </div>
            }

            {/* <CFormLabel htmlFor="exampleFormControlInput1">Працівник</CFormLabel> */}
            <CListGroup className="mb-3">
                {props.accJobsList &&
                    props.accJobsList.map((el) => {
                        return (
                            <CListGroupItem
                                key={el.id}
                                className="d-flex justify-content-between align-items-center"
                            >
                                {el.name}
                                <p>дозволено виконувати до {formatDateFromISO(el.dateEnd)}</p>

                                {/* props.isDisabledAll={true} - Заблокувати можливу зміну, оскільки дані вже вневені в 1С */}
                                {
                                    !props.isDisabledAll &&
                                    <CButton
                                        color="danger"
                                        variant="outline"
                                        onClick={() => delElWithArr(el.id)}
                                        disabled={props.isDisabledChange}
                                    >
                                        Видалити
                                    </CButton>
                                }
                            </CListGroupItem>
                        )
                    })}
            </CListGroup>
        </div>
    );
};

export default DangerWorks;