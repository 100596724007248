import React from 'react';
import { Link } from 'react-router-dom';
import '../../styles/info.css';

// Сторінка з інформацією про даний сайт (diamant Партнери), яка відкривається при переході з сайта https://diamant.inneti.net/
const Info = () => {
    return (
        <>
            <div className="info-header">
                <div>Кабінет Підрядника</div>
                <div>
                    <Link to="/login" className="ms-3 info-header__link">
                        УВІЙТИ
                    </Link>
                </div>
            </div>

            <div className="info-container">
                <div className="info-content">
                    <h2>Кабінет Підрядника</h2>
                    <p>Компанія ТзОВ «Радехівський цукор» - найбільший виробник цукру в Західній Україні та один з найбільших українських експортерів цукру та супроводжуючих продуктів, таких як жом та меляса.</p>
                    <p>Технічний персонал підприємства постійно працює над оптимізацією та автоматизацією технологічних режимів та процесів, займається модернізацією технічного та технологічного обладнання.</p>
                    <p>З метою забезпечення виконання робіт  по напрямку модернізації та впровадження нових технічних та технологічних ліній компанія ТзОВ «Радехівський цукор»  співпрацює з низкою кваліфікованих та спеціалізованих структур. Особливу увагу звертаємо на дотримання всіх вимог з охорони праці, адже в основі нашої роботи – люди та їхня безпека.</p>
                </div>
            </div>
        </>
    );
};

export default Info;