import React from 'react';
import { CForm, CFormLabel, CFormInput, CButton, CListGroup, CListGroupItem, CFormCheck } from '@coreui/react';
import AsyncSelect from 'react-select/async';
import Select from 'react-select'
import { Link, useLoaderData } from 'react-router-dom'

// Компонент для створення роботи
const CreateJobForm = (props) => {
    return (
        <CForm onSubmit={props.handleSubmit} className="mb-3">

            <div className="mb-3">
                <CFormLabel htmlFor="exampleFormControlInput1">Контрагент</CFormLabel>
                <AsyncSelect
                    cacheOptions
                    loadOptions={props.loadOptionsPartners}
                    defaultOptions
                    value={props.selectedOptionPartner}
                    onChange={props.handleChangePartner}
                    className="flex-grow-1"
                />
            </div>

            <div className="mb-3">
                <CFormLabel htmlFor="exampleFormControlInput1">Назва роботи</CFormLabel>
                <CFormInput
                    type="text"
                    id="exampleFormControlInput1"
                    value={props.name}
                    onChange={(e) => props.setName(e.target.value)}
                    autoComplete="off"
                />
            </div>
            <div className="mb-3">
                <CFormLabel htmlFor="exampleFormControlInput2">Опис</CFormLabel>
                <CFormInput
                    type="text"
                    id="exampleFormControlInput2"
                    value={props.description}
                    onChange={(e) => props.setDescription(e.target.value)}
                    autoComplete="off"
                />
            </div>

            <div className="mb-3">
                <CFormLabel htmlFor="exampleFormControlInput2">Дата початку роботи</CFormLabel>
                <CFormInput
                    type="date"
                    id="exampleFormControlInput2"
                    value={props.descriptionDateStart}
                    onChange={(e) => props.setDescriptionDateStart(e.target.value)}
                    autoComplete="off"
                />
            </div>

            <div className="mb-3">
                <CFormLabel htmlFor="exampleFormControlInput2">Дата закінчення роботи</CFormLabel>
                <CFormInput
                    type="date"
                    id="exampleFormControlInput2"
                    value={props.descriptionDateEnd}
                    onChange={(e) => props.setDescriptionDateEnd(e.target.value)}
                    autoComplete="off"
                />
            </div>

            <div className="mb-3">
                <CFormLabel htmlFor="exampleFormControlInput1">Підрозділ</CFormLabel>
                <AsyncSelect
                    cacheOptions
                    loadOptions={props.loadOptionsFactories}
                    defaultOptions
                    value={props.selectedOptionFactories}
                    onChange={props.handleChangeFactories}
                    className="flex-grow-1"
                />
            </div>

            <div className="mb-3">
                <CFormLabel htmlFor="exampleFormControlInput1">Відповідальний інженер</CFormLabel>
                {/* <AsyncSelect */}
                <Select
                    cacheOptions
                    // loadOptions={loadOptionsEngineers}
                    options={props.selectedOptionEngineers}
                    defaultOptions
                    value={props.selectedEngineer}
                    onChange={props.handleChangeEngineers}
                    className="flex-grow-1"
                />
            </div>

            <CButton type="submit" color="success">
                Зберегти
            </CButton>
            <Link to={props.urlRouter} className="ms-3">
                <CButton type="submit" color="secondary">
                    Відміна
                </CButton>
            </Link>
        </CForm>
    );
};

export default CreateJobForm;