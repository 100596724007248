import React from 'react';
import { CForm, CFormLabel, CFormInput, CButton, CListGroup, CListGroupItem, CFormCheck, CFormTextarea } from '@coreui/react';
import { cilTrash } from '@coreui/icons';
import CIcon from '@coreui/icons-react';

// Компонент для додаткових матеріалів
const AdditionalMaterials = (props) => {
    return (
        <div className="mb-4">
            <div>
                <CFormLabel htmlFor="exampleFormControlFile1" style={{ fontWeight: 'bold' }}>Додаткові матеріали</CFormLabel>

                {/* props.isDisabledAll={true} - Заблокувати можливу зміну, оскільки дані вже вневені в 1С */}
                {
                    !props.isDisabledAll &&
                    <div className="col d-flex justify-content-end mb-3">
                        <CFormInput
                            type="file"
                            id="exampleFormControlFile1"
                            onChange={(e) => props.handleFileChangeState(e, props.setFile)}
                            multiple
                            disabled={props.isDisabledChange}
                        />
                        <CButton className="ms-3" type="submit" color="secondary" disabled={!props.file || props.isDisabledChange}>
                            +
                        </CButton>
                    </div>
                }
            </div>

            {/* <div className="col d-flex justify-content-end"></div> */}

            <div className={props.css.wrapperButton}>
                <p className="h6">Наявні документи</p>
                {props.arrFile.map((el, index) => (
                    <div className={props.css.file_block} key={index}>
                        <a
                            href={process.env.REACT_APP_SERVER_URL + el.contentUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {el.contentUrl}
                        </a>
                        {/* {el.contentUrl.endsWith('.jpg') ? (
                <img
                  src={process.env.REACT_APP_SERVER_URL + el.contentUrl}
                  width="50px"
                  alt={`Image ${index}`}
                />
              ) : (
                <a
                  href={process.env.REACT_APP_SERVER_URL + el.contentUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {el.contentUrl}
                </a>
              )} */}

                        {/* props.isDisabledAll={true} - Заблокувати можливу зміну, оскільки дані вже вневені в 1С */}
                        {
                            !props.isDisabledAll &&
                            <CButton
                                color="danger" variant="outline"
                                onClick={() => props.deleteFile(el, "additionalMaterials")}
                                disabled={props.isDisabledChange}
                            >
                                <CIcon icon={cilTrash} customClassName="nav-icon" height={20} />
                            </CButton>
                        }
                    </div>
                ))}
            </div>
        </div>
    );
};

export default AdditionalMaterials;