import React, { useState } from 'react'
import { CCard, CCardBody, CCardHeader, CFormInput, CCol, CRow, CButton, CAccordion, CAccordionItem, CAccordionHeader, CAccordionBody } from '@coreui/react'
import { DataGrid } from '@mui/x-data-grid'
import { Link, useLoaderData, useLocation } from 'react-router-dom'
import AxiosInstance from 'src/components/Axios'
import { cilPen, cilPlus, cilTrash } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { useEffect } from 'react'
import { confirmAlert } from 'react-confirm-alert'
import AsyncSelect from 'react-select/async'
// import { Select } from '@mui/material'
import Select from 'react-select';


const page = 1
const urlApi = '/jobs'
const urlRouter = '/jobs'

// хто увійшов
const role = localStorage.getItem('user_role')
// console.log(role)

// дані користувача
const userData = JSON.parse(localStorage.getItem('user_data'))
const tokenStr = localStorage.getItem('user')
const header = { headers: { Authorization: `Bearer ${tokenStr}`, accept: 'application/json' } }

// перше завантаження
export async function LoaderMyJobs({ params }) {
  let query = `page=${page}`
  const request = `account.user.id=${userData.id}`

  // запит тільки даних користувача
  if (role === 'ROLE_ADMIN') {
    // console.log('запит адмін')
    let res = await AxiosInstance.get(`${urlApi}?${query}`).then((response) => {
      return response
    })
    return res
  } else {
    // console.log('запит партнера')
    let res = await AxiosInstance.get(`${urlApi}?${query}&${request}`).then((response) => {
      return response
    })
    return res
  }
}

// головний контент
const MyJobs = () => {
  const { data } = useLoaderData()
  // console.log('data Jobs', data)
  const { state } = useLocation();
  // console.log('state1', state);

  const [rows, setRows] = useState(false)
  const [rowCountState, setRowCountState] = useState(0)
  // для пошуку
  const [nameFilterSearch, setNameFilterSearch] = useState('')
  const [partnerFilterSearch, setPartnerFilterSearch] = useState('')
  const [statusFilterSearch, setStatusFilterSearch] = useState('')
  const [factoryFilterSearch, setFactoryFilterSearch] = useState('')
  // Заблокувати кнопку фільрації якщо не введені символи для пошуку
  const [disabled, setDisabled] = useState(true)

  useEffect(()=> {
    console.log('statusFilterSearch', statusFilterSearch);
  },[statusFilterSearch])

  // Список можливих статусів
  const optionsStatus = [
    { value: 'new', label: 'Нова' },
    { value: 'not_checked', label: 'Не перевірено' },
    { value: 'rejected', label: 'Зауваження' },
    { value: 'checked', label: 'Перевірено' },
    { value: 'synced', label: '1С' },
  ];

  // Якщо лінк відбувся з головної сторінки по "not_checked", то віддразу відфільтрувати
  useEffect(() => {
    // console.log('state', state);
    // console.log("фільтр по статус");
    if (state?.status === "not_checked") {
      // Задати значення "Статус" в фільтрі для коректного відображення активного фільтра
      setStatusFilterSearch({ value: 'not_checked', label: 'Не перевірено' });
      AxiosInstance.get(`${urlApi}?status=${state.status}`).then((response) => {
        setRows(response.data['hydra:member']);
        setRowCountState(response.data['hydra:totalItems']);
      });
    }
  }, [state]);

  // перевірка чи є масив даних
  if (!rows) {
    setRows(data['hydra:member']) //масив
    setRowCountState(data['hydra:totalItems']) //кількість
  }

  // Фільтр
  const getRowsTable = (p = 1) => {
    let request = ``
    // перевірка чи є щось в імені
    if (nameFilterSearch !== '') {
      request += `&name=${nameFilterSearch}`
      if (role === 'ROLE_ADMIN') request += `&name=${nameFilterSearch}`
      if (role === 'ROLE_PARTNER') request += `&accountWorkers.account.${nameFilterSearch}`
    }

    // якщо вибрананий контрагент

    if (partnerFilterSearch !== '') request += `&account.name=${partnerFilterSearch.label}`
    if (statusFilterSearch !== '') request += `&status=${statusFilterSearch.value}`
    if (factoryFilterSearch !== '') request += `&factory.id=${factoryFilterSearch.value}`

    // відображаємо тільки з даних користувача
    if (role === 'ROLE_PARTNER') request += `&user.id=${userData.id}`

    // запит після пошуку
    AxiosInstance.get(`${urlApi}?page=${p}${request}`).then((response) => {
      setRows(response.data['hydra:member'])
      setRowCountState(response.data['hydra:totalItems'])
    })
  }

  // зміна сторінки
  const handlePageChange = (page) => {
    getRowsTable(page + 1)
  }

  // Заблокувати кнопку фільтрації, якщо не введені символи для пошуку
  const disabledButton = () => {
    setDisabled(
      typeof partnerFilterSearch === 'string' &&
      partnerFilterSearch.trim() === '' &&
      nameFilterSearch.trim() === '' &&
      statusFilterSearch === '' &&
      factoryFilterSearch === ''
    );
  };

  // Розблокувати кнопку, якщо хоча б одне поле містить значення
  useEffect(() => {
    disabledButton()
  }, [nameFilterSearch, partnerFilterSearch, statusFilterSearch, factoryFilterSearch])

  // зміна стейту пошуку "Назва"
  const handleNameFilterChange = (e) => {
    setNameFilterSearch(e.target.value)
    disabledButton(e)
  }

  // Фільтрування
  const handleFilterSearch = () => {
    getRowsTable()
  }

  // Скинути фільтр і показати весь список
  const handleFilterReset = () => {
    setNameFilterSearch('')
    setPartnerFilterSearch('')
    setStatusFilterSearch('')
    setFactoryFilterSearch('')
    setDisabled(true)
    getRowsTable()
  }

  async function deleted(row) {
    try {
      await confirmAlert({
        title: 'Ви впевнені що хочите видалити цей пункт ?',
        message: 'Are you sure to do this.',
        buttons: [
          {
            label: 'Так',
            onClick: async () => {
              const response = await AxiosInstance.delete(`${urlApi}/${row.id}`)

              console.log('Пункт успішно видалено.', response)

              getRowsTable()
            },
          },
          {
            label: 'Ні',
            onClick: () => {
              return
            },
          },
        ],
      })
    } catch (error) {
      console.error('Помилка видалення:', error)
    }
  }

  // Дані, які навнюються в таблицю
  const columns = [
    { field: 'id', headerName: 'ID', width: 50 },
    { field: 'code1c', headerName: 'code1c', width: 90 },
    {
      field: 'name',
      headerName: 'Назва',
      width: 150,
      renderCell: (params) => {
        return (
          <Link
            to={`${urlRouter}/update/${params.row.id}`}
            // style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)' }}
            style={{ textDecoration: 'none' }}
          >
            {params.row.name}
          </Link>
        )
      },
    },

    { field: 'description', headerName: 'Опис', width: 200 },
    { field: 'status', headerName: 'Статус', width: 110 },

    {
      field: 'action',
      headerName: 'Action',
      width: 70,
      sortable: false,
      renderCell: (params) => {
        return (
          <Link to={`${urlRouter}/update/${params.row.id}`}>
            <CButton color="dark" variant="outline">
              <CIcon icon={cilPen} customClassName="nav-icon" height={20} />
            </CButton>
          </Link>
        )
      },
    },
    {
      field: 'factory',
      headerName: 'Підрозділ',
      width: 185,
      renderCell: (params) => {
        return (
          <span>
            {params.row.factory?.name ? params.row.factory.name : ""}
          </span>
        )
      },
    },
    {
      field: 'params.row.account.name',
      headerName: 'Контрагент',
      width: 380,
      renderCell: (params) => {
        return (
          // <Link
          //   to={`${urlRouter}/update/${params.row.id}`}
          //   // style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)' }}
          //   style={{ textDecoration: 'none' }}
          // >
          // {params.row.account.name}
          // </Link>
          params.row.account.name
        )
      },
    },

    {
      field: 'delete',
      headerName: 'Видалити',
      width: 80,
      sortable: false,
      renderCell: (params) => {
        return (
          <CButton
            color="danger"
            variant="outline"
            onClick={() => {
              deleted(params.row)
            }}
          >
            <CIcon icon={cilTrash} customClassName="nav-icon" height={20} />
          </CButton>
        )
      },
    },
  ]

  // Натиснення кнопки: "Enter" - виконати пошук; "Escape" - скинути пошук
  const keyDown = (event) => {
    // Виконати пошук
    if (event.key === 'Enter' && !disabled) {
      event.preventDefault()
      handleFilterSearch(event)
    }
    // Скинути пошук
    if (event.key === 'Escape') {
      event.preventDefault()
      handleFilterReset(event)
    }
  }



// логіка запиту у фільтрації 

const loadOptions = async (inputValue) => {
  if (role === 'ROLE_ADMIN') {
    return await AxiosInstance.get(`/accounts?name=${inputValue}`, header).then((response) => {
      return response.data.map((result) => ({
        label: result.name,
        value: result.id,
      }));
    });
  } else {
    if (userData.id) {
      return await AxiosInstance.get(`/accounts?user.id=${userData.id}&name=${inputValue}`, header).then((response) => {
        return response.data.map((result) => ({
          label: result.name,
          value: result.id,
        }));
      });
    }
  }
}



  // Завантажити список підрозділів
  const loadOptionsFactory = async (inputValue) => {
    return await AxiosInstance.get(`/factories`, header).then((response) => {
      // console.log('response get /factories', response)
      return response.data.map((result) => ({
        label: result.name,
        value: result.id,
      }))
    })
  }

  // при зміні опції
  const handleChangePartner = (select) => {
    setPartnerFilterSearch(select)
  }
  // const [selectedOption, setSelectedOption] = useState(null);

  const handleChangeStatus = (select) => {
    setStatusFilterSearch(select)
  }

  // при зміні опції
  const handleChangeFactory = (select) => {
    setFactoryFilterSearch(select)
  }

  // const handleChange = (selectedOption) => {
  //   setSelectedOption(selectedOption);
  // };

  return (
    <CRow>
      <CCol xs={12}>
        <CCard className="mb-4">
          <CAccordion flush>
            <CAccordionItem>
              <CAccordionHeader onClick={loadOptions}>
                <strong>Фільтр</strong>
              </CAccordionHeader>
              <CAccordionBody>
                <CRow className="my-3 mx-2">
                  <CCol md={9} className="me-auto">
                    <CRow>
                      <CCol md={3} className="filter-field">
                        <CFormInput
                          placeholder="Назва"
                          id="nameFilterSearch"
                          aria-label="Пошук"
                          className="me-2"
                          onChange={handleNameFilterChange}
                          value={nameFilterSearch}
                          onKeyDown={keyDown}
                          autoComplete="off"
                        />
                      </CCol>

                      <CCol md={3} className="filter-field">
                        {/* <CFormLabel htmlFor="exampleFormControlInput1">Контрагент</CFormLabel> */}
                        <AsyncSelect
                          cacheOptions
                          loadOptions={loadOptions}
                          defaultOptions
                          value={partnerFilterSearch}
                          onChange={handleChangePartner}
                          className="flex-grow-1"
                          placeholder="Контрагент"
                        />
                      </CCol>

                      <CCol md={3} className="filter-field">
                        <Select
                          options={optionsStatus}
                          value={statusFilterSearch}
                          onChange={handleChangeStatus}
                          className="flex-grow-1"
                          placeholder="Статус"
                        />
                      </CCol>

                      <CCol md={3} className="filter-field">
                        <AsyncSelect
                          cacheOptions
                          loadOptions={loadOptionsFactory}
                          defaultOptions
                          value={factoryFilterSearch}
                          onChange={handleChangeFactory}
                          className="flex-grow-1"
                          placeholder="Підрозділ"
                        />
                      </CCol>
                    </CRow>
                  </CCol>

                  <CCol md={3} style={{ textAlign: 'end' }}>
                    <CButton
                      className="me-2"
                      type="submit"
                      color="secondary"
                      onClick={handleFilterSearch}
                      disabled={disabled}
                    >
                      Пошук
                    </CButton>
                    <CButton type="submit" color="secondary" onClick={handleFilterReset}>
                      X
                    </CButton>
                  </CCol>
                </CRow>
              </CAccordionBody>
            </CAccordionItem>
          </CAccordion>

          <CCardHeader>
            <strong>Роботи</strong> <small></small>
          </CCardHeader>
          <CCardBody>
            <p className="text-medium-emphasis small">
              <Link to={`${urlRouter}/create`}>
                <CButton color="secondary">
                  <CIcon icon={cilPlus} customClassName="nav-icon" height={15} />
                  Створити
                </CButton>
              </Link>
            </p>
            <div style={{ height: 400, width: '100%', }}>
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={30}
                rowsPerPageOptions={[30]}
                pagination
                rowCount={rowCountState}
                paginationMode="server"
                onPageChange={handlePageChange}
              />
            </div>
          </CCardBody>
        </CCard>
      </CCol >
    </CRow >
  )
}

export default MyJobs
