import React, { useEffect, useRef, useState } from 'react';
import { useLoaderData, useLocation } from 'react-router-dom';
import AxiosInstance from 'src/components/Axios';
import { Editor } from '@tinymce/tinymce-react';
import { useNavigate } from "react-router-dom";
import { CForm, CFormLabel, CFormInput, CButton, CFormTextarea, CFormCheck } from '@coreui/react';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MyToastify from 'src/components/myComponents/MyToactify';
import FaqsCategories from 'src/components/faq/FaqsCategories';

const urlApi = '/faqs';
const urlRouter = '/faq';

export async function LoaderFaqUpdate({ params }) {
  if (Object.keys(params).length != 0) {
    let res = await AxiosInstance.get(`${urlApi}/${params.pid}`).then((response) => {
      return response;
    });
    return res;
  }
  return false;
};

const formReducer = (state, event) => {
  return {
    ...state,
    [event.target.name]: event.target.value
  }
};

const FaqForm = () => {
  const navigate = useNavigate();
  const { data } = useLoaderData();
  const [name, setName] = useState((data != undefined) ? data.name : '');
  const [contentShort, setContentShort] = useState((data != undefined) ? data.contentShort : '');
  const [active, setActive] = useState((data != undefined) ? data.active : '0');
  const [top, setTop] = useState((data != undefined) ? data.top : '0');
  const editorRef = useRef(null); // data.contentFull

  // Викликає popup з власного компонента
  const popup = MyToastify();
  // Текст для popup
  const popupTextPutSuccess = "FAQ оновлено";
  const popupTextPostSuccess = "FAQ створено";
  const popupTextWarning = "Error";

  // Сьогоднішня дата
  const todayDate = new Date();
  const date = todayDate.getDate() + "-" + (todayDate.getMonth() + 1) + "-" + todayDate.getFullYear();

  // Для checkbox при ініціалазації
  const initActive = (active != 1) ? false : true;
  const initTop = (top != 1) ? false : true;
  // Зміна checkbox
  const [changeActive, setChangeActive] = useState(initActive);
  const [changeTop, setChangeTop] = useState(initTop);

  // useEffect(() => {
  //   console.log('data', data);
  // }, [data]);

  // Для зміни значення checkbox для поля "Актикний"
  useEffect(() => {
    changeActive ? setActive(1) : setActive(0);
  }, [changeActive]);

  // Для зміни значення checkbox для поля "Хіти продаж"
  useEffect(() => {
    changeTop ? setTop(1) : setTop(0);
  }, [changeTop]);

  const handleSubmit = event => {
    event.preventDefault();
    if (editorRef.current) {
      // console.log(editorRef.current.getContent());
      const article = {
        name: name,
        date: date,
        contentShort: contentShort,
        contentFull: editorRef.current.getContent(),
        active: active.toString(),
        top: top.toString()
      };

      // console.log('article', article);

      if (data != undefined) {
        AxiosInstance.put(`${urlApi}/${data.id}`, article).then((response) => {
          console.log("put response", response);
          if (response.status === 200) {
            // Popup про успішні зміни
            popup.toastifySuccess(popupTextPutSuccess);
            return navigate("/faq/update/" + response.data.id);
          } else {
            alert("Error");
            // Popup попередження
            popup.toastifyWarning(popupTextWarning);
          };
        });
      } else {
        AxiosInstance.post(`${urlApi}`, article).then((response) => {
          console.log("post create response", response);
          if (response.status === 201) {
            // Popup про успішні зміни
            popup.toastifySuccess(popupTextPostSuccess);
            return navigate("/faq/update/" + response.data.id);
          }
        });
      }
    }
  };

  return (
    <>
      {/* Popup про успішні зміни (справа зверху) */}
      <ToastContainer />

      <CForm onSubmit={handleSubmit}>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Назва</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput1" value={name} onChange={(e) => setName(e.target.value)} />
        </div>
        {/* <div className="mb-3">
          <CFormCheck id="exampleFormControlInput8" label="Активний" checked={initActive} onChange={() => setChangeActive(!changeActive)} />
        </div>
        <div className="mb-3">
          <CFormCheck id="exampleFormControlInput8" label="Хіт продаж" checked={initTop} onChange={() => setChangeTop(!changeTop)} />
        </div> */}
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlTextarea1">Короткий опис</CFormLabel>
          <CFormTextarea id="exampleFormControlTextarea1" rows={3} value={contentShort} onChange={(e) => setContentShort(e.target.value)}></CFormTextarea>
        </div>
        <div className="mb-3">
          <Editor
            onInit={(evt, editor) => editorRef.current = editor}
            initialValue={(data != undefined) ? data.contentFull : ''}
            init={{
              height: 250,
              menubar: true,
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount'
              ],
              toolbar: 'undo redo | formatselect | ' +
                'bold italic backcolor | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist outdent indent | ' +
                'removeformat | help | link image | code',
              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
              style_formats: [
                { title: 'Headers', items: [
                  { title: 'Header 1', format: 'h1' },
                  { title: 'Header 2', format: 'h2' },
                  { title: 'Header 3', format: 'h3' },
                  { title: 'Header 4', format: 'h4' },
                  { title: 'Header 5', format: 'h5' },
                  { title: 'Header 6', format: 'h6' },
                ] },
                { title: 'Font Sizes', items: [
                  { title: 'Small', format: 'small' },
                  { title: 'Normal', format: 'normal' },
                  { title: 'Large', format: 'large' },
                  { title: 'Huge', format: 'huge' },
                ] },
              ],
            }}
          />
        </div>

        <div className="mb-3">
          <CButton type="submit" color="success">Зберегти</CButton>
          <Link to={urlRouter} className="ms-3"><CButton type="submit" color="secondary">Відміна</CButton></Link>
        </div>
      </CForm>

      <hr />
      {/* {(MyData != undefined) ? <AttributesCategories attribute_id={MyData.id} toastifySuccess={popup.toastifySuccess} toastifyWarning={popup.toastifyWarning} /> : ''} */}
      {/* {(data != undefined) ? <FaqsCategories faq_id={data.id} toastifySuccess={popup.toastifySuccess} toastifyWarning={popup.toastifyWarning} /> : ''} */}
    </>
  );
};

export default FaqForm;