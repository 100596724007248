import React from 'react';
import { Modal, StyledEngineProvider, Typography, Box } from '@mui/material';
import { CButton } from '@coreui/react';

// Попередження при зміні status="checked"
const WarningWhenChecked = (props) => {

    // Стилі для модального вікна
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 1,
    };

    // Функція для підтвердження та закриття модального вікна
    const handleChange = () => {
        // Підтвердити
        props.changeStatus("checked");
        // Закрити модальне вікно
        props.handleCloseWarning();
    }

    return (
        <StyledEngineProvider injectFirst>
            <Modal
                open={props.openWarning}
                onClose={props.handleCloseWarning}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        <div style={{ padding: "10px", textAlign: "center", color: "red" }}>
                            <h2>Попередження!!!</h2>
                            <p>Після підтвердження роботи зміни можна буде вносити тільки в 1С</p>
                            <CButton
                                className="me-3"
                                onClick={() => handleChange()}
                                color="success"
                            >
                                Перевірено
                            </CButton>
                            <CButton color="secondary" onClick={() => props.handleCloseWarning()}>Відміна</CButton>
                        </div>
                    </Typography>
                </Box>
            </Modal>
        </StyledEngineProvider>
    );
};

export default WarningWhenChecked;