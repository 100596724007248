import React from 'react';
import { CButton } from '@coreui/react';

// Блок кнопок
const ButtonsBlock = (props) => {
    return (
        <div>
            {
                (!props.isDisabledChange && !props.isSendForInspection) &&
                <CButton onClick={() => props.changeStatus("not_checked")} color="success" disabled={props.isDisabledSend}>
                    Відправити на перевірку
                </CButton>
            }

            {/* Редагувати файли (status="not_checked") */}
            {
                (props.isDisabledChange && props.isSendForInspection) &&
                <CButton onClick={() => props.changeStatus("new")} color="success">
                    Редагувати
                </CButton>
            }

            {/* Відображати тільки для Адміна */}
            {
                (props.role === "ROLE_ADMIN") &&
                <>
                    {/* Затвердити файли (status="checked") */}
                    {
                        (props.dataState?.status === "not_checked") &&
                        <CButton
                            className="ms-3"
                            // onClick={() => props.changeStatus("checked")}
                            onClick={() => props.setOpenWarning(true)}
                            color="success"
                        >
                            Перевірено
                        </CButton>
                    }


                    {/* Повернути файли на доопрацювання (status="rejected") */}
                    {
                        (props.dataState?.status === "not_checked") &&
                        <>
                            <CButton
                                className="ms-3"
                                onClick={() => props.addComentar(props.comentar)}
                                color="success"
                                disabled={props.dataState?.status === "rejected" || props.comentar === ""}
                            >
                                {(props.dataState?.comentar && props.dataState.comentar !== "") ? "Оновити зауваження" : "Додати зауваження"}
                            </CButton>
                        </>
                    }
                </>
            }
        </div>
    );
};

export default ButtonsBlock;