import React from 'react';
import { CForm, CFormLabel, CFormInput, CButton, CListGroup, CListGroupItem, CFormCheck } from '@coreui/react';
import { cilTrash } from '@coreui/icons';
import CIcon from '@coreui/icons-react';

// Компонент про відповідального за Охорону праці
const ResponsibleForLaborProtection = (props) => {
    return (
        <div className="mb-4">
            <CFormLabel htmlFor="exampleFormControlFile1" style={{ fontWeight: 'bold' }}>
                Наказ "Про відповідального за Охорону праці"
            </CFormLabel>

            {/* props.isDisabledAll={true} - Заблокувати можливу зміну, оскільки дані вже вневені в 1С */}
            {
                !props.isDisabledAll &&
                <div className="col d-flex justify-content-end mb-3">
                    <CFormInput
                        type="file"
                        id="exampleFormControlFile1"
                        onChange={(e) => props.handleFileChangeState(e, props.setCommandmentState)}
                        multiple
                        disabled={props.disabledChange}
                    />
                    <CButton
                        className="ms-3"
                        onClick={(e) => props.handleAddFile(props.commandmentState, 'commandment', props.urlApi)}
                        color="secondary"
                        disabled={!props.commandmentState ? true : false}
                    >
                        +
                    </CButton>
                </div>
            }

            {props.myDataState && props.myDataState.commandment ? (
                <div className={props.css.wrapperButton}>
                    <p className="h6">Існуючий документ</p>
                    <div className={props.css.file_block}>
                        {/* {props.myDataState.commandment.contentUrl.endsWith('.jpg') ? (
                      <img
                        src={process.env.REACT_APP_SERVER_URL + props.myDataState.commandment.contentUrl}
                        width="50px"
                        alt={`Image`}
                      />
                      
                    ) : (
                      <a
                        href={process.env.REACT_APP_SERVER_URL + props.myDataState.commandment.contentUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {props.myDataState.commandment.contentUrl}
                      </a>
                    )} */}
                        <a
                            href={process.env.REACT_APP_SERVER_URL + props.myDataState.commandment.contentUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {props.myDataState.commandment.contentUrl}
                        </a>

                        {/* props.isDisabledAll={true} - Заблокувати можливу зміну, оскільки дані вже вневені в 1С */}
                        {
                            !props.isDisabledAll &&
                            <CButton
                                color="danger"
                                variant="outline"
                                onClick={() => props.deleteFile(props.myDataState.commandment, "commandment")}
                                disabled={props.disabledChange}
                            >
                                <CIcon icon={cilTrash} customClassName="nav-icon" height={20} />
                            </CButton>
                        }
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default ResponsibleForLaborProtection;