import React from 'react';
import { Modal, StyledEngineProvider, Typography, Box } from '@mui/material';
import { CButton } from '@coreui/react';

// Модальне вікно з попередженням
const WarningPopup = (props) => {

    // Стилі для модального вікна
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 1,
    };

    return (
        <StyledEngineProvider injectFirst>
            <Modal
                open={props.openWarning}
                onClose={props.handleCloseWarning}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        <div style={{ padding: "10px", textAlign: "center", color: "red" }}>
                            <h2>Попередження!!!</h2>
                            <p>{props.text}</p>
                            {/* {
                                (props.text != undefined) ?
                                    <p>{props.text}</p>
                                    :
                                    <p>Не рекомендуємо редагувати товари, бо вони синхронізуються з 1С</p>
                            } */}
                            <CButton color="secondary" onClick={() => props.handleCloseWarning()}>Добре</CButton>
                        </div>
                    </Typography>
                </Box>
            </Modal>
        </StyledEngineProvider>
    );
};

export default WarningPopup;