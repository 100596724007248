import React, { useState } from 'react'
import {
  CCard,
  CCardBody,
  CCardHeader,
  CFormInput,
  CCol,
  CRow,
  CButton,
  CAccordion,
  CAccordionItem,
  CAccordionHeader,
  CAccordionBody,
} from '@coreui/react'
import { DataGrid } from '@mui/x-data-grid'
import { Link, useLoaderData, useLocation } from 'react-router-dom'
import AxiosInstance from 'src/components/Axios'
import { cilPen, cilPlus, cilTrash } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { useEffect } from 'react'
import { confirmAlert } from 'react-confirm-alert'
import Select from 'react-select';


// const postPerPage = 0;
// const pageSize = 20;
const page = 1
const urlApi = '/accounts'
const urlRouter = '/accounts'

// хто увійшов
const role = localStorage.getItem('user_role')
// дані користувача
const userData = JSON.parse(localStorage.getItem('user_data'))

// перший завантаження
export async function LoaderAccounts({ params }) {
  let query = `page=${page}`

  // запит тільки даних користувача
  if (role === 'ROLE_PARTNER') {
    query += `&user.id=${userData.id}`
  }
  let res = await AxiosInstance.get(`${urlApi}?${query}`).then((response) => {
    // console.log('перші дані контрагентів', response);
    return response
  })
  return res
}

// головний контент
const Accounts = () => {
  const location = useLocation();
  const status = location.state ? location.state.status : null;
  // console.log('status', status);




  const { data } = useLoaderData()
  // console.log('data1 accounts', data)

  const { state } = useLocation();
  // console.log('state1', state);

  const [rows, setRows] = useState(false)
  const [rowCountState, setRowCountState] = useState(0)
  // для пошуку
  const [nameFilterSearch, setNameFilterSearch] = useState('')

  const [edrpouFilterSearch, setEdrpouFilterSearch] = useState('')
  const [statusFilterSearch, setStatusFilterSearch] = useState(status ? status.value : '')

  const [managerFirstNameFilterSearch, setManagerFirstNameFilterSearch] = useState('')
  // Заблокувати кнопку фільрації якщо не введені символи для пошуку
  const [disabled, setDisabled] = useState(true)


  useEffect(() => {
    console.log('statusFilterSearch', statusFilterSearch);

  }, [statusFilterSearch])

  // Якщо лінк відбувся з головної сторінки по "not_checked", то віддразу відфільтрувати
  useEffect(() => {
    // console.log('state', state);
    // console.log("фільтр по статус");
    if (state?.status === "not_checked") {
      // Задати значення "Статус" в фільтрі для коректного відображення активного фільтра
      setStatusFilterSearch({ value: 'not_checked', label: 'Не перевірено' });
      AxiosInstance.get(`${urlApi}?accountJobsLists.status=${state.status}`).then((response) => {
        setRows(response.data['hydra:member']);
        setRowCountState(response.data['hydra:totalItems']);
      });
    }
  }, [state]);

  // перевірка чи є масив даних
  if (!rows) {
    setRows(data['hydra:member']) //масив
    setRowCountState(data['hydra:totalItems']) //кількість
  }

  // Фільтр
  const getRowsTable = (p = 1) => {
    let request = ``
    // перевірка чи є щось в імені
    if (nameFilterSearch != '') {
      request += `&name=${nameFilterSearch}`
    }
    // перевірка чи є щось в ЄДРПОУ
    if (edrpouFilterSearch != '') {
      request += `&edrpou=${edrpouFilterSearch}`
    }
    // перевірка чи є щось в менеджері
    if (managerFirstNameFilterSearch != '') {
      request += `&manager.firstName=${managerFirstNameFilterSearch}`
    }

    if (statusFilterSearch?.value === 'not_checked') {
      request += `&accountJobsLists.status=${statusFilterSearch.value}`
    }


    // відображаємо тільки з даних користувача
    if (role === 'ROLE_PARTNER') request += `&user.id=${userData.id}`

    // запит після пошуку
    AxiosInstance.get(`${urlApi}?page=${p}${request}`).then((response) => {
      setRows(response.data['hydra:member'])
      setRowCountState(response.data['hydra:totalItems'])
    })
  }

  // зміна сторінки
  const handlePageChange = (page) => {
    getRowsTable(page + 1)
  }

  // Заблокувати кнопку фільтрації, якщо не введені символи для пошуку
  const disabledButton = () => {
    setDisabled(nameFilterSearch.trim() === '' && managerFirstNameFilterSearch.trim() === '' && edrpouFilterSearch.trim() === '' && statusFilterSearch === '')
  }

  useEffect(() => {
    // Розблокувати кнопку, якщо хоча б одне поле містить значення
    disabledButton()
  }, [nameFilterSearch, managerFirstNameFilterSearch, edrpouFilterSearch, statusFilterSearch])

  // зміна стейту пошуку
  const handleNameFilterChange = (e) => {
    setNameFilterSearch(e.target.value)
    disabledButton(e)
  }


  const handleChangeStatus = (select) => {
    setStatusFilterSearch(select)
  }

  const handleEdrpouFilterSearch = (e) => {
    setEdrpouFilterSearch(e.target.value)
    disabledButton(e)
  }

  const handleManagerFirstNameFilterChange = (e) => {
    setManagerFirstNameFilterSearch(e.target.value)
    disabledButton(e)
  }

  // Фільтрування
  const handleFilterSearch = () => {
    getRowsTable()
  }

  // Скинути фільтр і показати весь список
  const handleFilterReset = () => {
    setNameFilterSearch('')
    setManagerFirstNameFilterSearch('')
    setDisabled(true)
    getRowsTable()
  }

  // async function deleted(row) {
  //   try {
  //     await confirmAlert({
  //       title: 'Ви впевнені що хочите видалити цей пункт ?',
  //       message: 'Are you sure to do this.',
  //       buttons: [
  //         {
  //           label: 'Так',
  //           onClick: async () => {
  //             const response = await AxiosInstance.delete(`${urlApi}/${row.id}`)

  //             console.log('Пункт успішно видалено.', response)

  //             getRowsTable()
  //           },
  //         },
  //         {
  //           label: 'Ні',
  //           onClick: () => {
  //             return
  //           },
  //         },
  //       ],
  //     })
  //   } catch (error) {
  //     console.error('Помилка видалення:', error)
  //   }
  // }

  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    {
      field: 'name',
      headerName: 'Назва',
      width: 600,
      renderCell: (params) => {
        return (
          <Link
            to={`${urlRouter}/update/${params.row.id}`}
            // style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)' }}
            style={{ textDecoration: 'none' }}
          >
            {params.row.name}
          </Link>
        )
      },
    },
    { field: 'edrpou', headerName: 'ЄДРПОУ', width: 200 },
    // { field: 'edrpou', headerName: 'Не перевірені дозволи', width: 200 },
    // { field: 'description', headerName: 'Опис', width: 200 },
    // {
    //   field: 'manager',
    //   headerName: 'Менеджер',
    //   sortable: false,
    //   width: 300,
    //   renderCell: (params) => {
    //     return params.row.manager != undefined
    //       ? `(${params.row.manager.username}) ${params.row.manager.firstName} ${params.row.manager.firstName}`
    //       : ''
    //   },
    // },
    // {
    //   field: 'action',
    //   headerName: 'Action',
    //   sortable: false,
    //   renderCell: (params) => {
    //     return (
    //       <Link to={`${urlRouter}/update/${params.row.id}`}>
    //         <CButton color="dark" variant="outline">
    //           <CIcon icon={cilPen} customClassName="nav-icon" height={20} />
    //         </CButton>
    //       </Link>
    //     )
    //   },
    // },
    // {
    //   field: 'delete',
    //   headerName: 'Видалити',
    //   sortable: false,
    //   renderCell: (params) => {
    //     return (
    //       <CButton
    //         color="danger"
    //         variant="outline"
    //         onClick={() => {
    //           deleted(params.row)
    //         }}
    //       >
    //         <CIcon icon={cilTrash} customClassName="nav-icon" height={20} />
    //       </CButton>
    //     )
    //   },
    // },
  ]

  // Натиснення кнопки: "Enter" - виконати пошук; "Escape" - скинути пошук
  const keyDown = (event) => {
    // Виконати пошук
    if (event.key === 'Enter' && !disabled) {
      event.preventDefault()
      handleFilterSearch(event)
    }
    // Скинути пошук
    if (event.key === 'Escape') {
      event.preventDefault()
      handleFilterReset(event)
    }
  }

    // Список можливих статусів
    const optionsStatus = [
      // { value: 'new', label: 'Нова' },
      { value: 'not_checked', label: 'Не перевірено дозвіл' },
      // { value: 'rejected', label: 'Зауваження' },
      { value: 'all', label: 'Всі' },
  
    ];

  return (
    <CRow>
      <CCol xs={12}>
        <CCard className="mb-4">
          <CAccordion flush>
            <CAccordionItem>
              <CAccordionHeader>
                <strong>Фільтр</strong>
              </CAccordionHeader>
              <CAccordionBody>

                <CRow className="my-3 mx-2">
                  <CCol md={9} className="me-auto">
                    <CRow>
                      <CCol md={4} className="filter-field">
                        <CFormInput
                          placeholder="Назва"
                          id="nameFilterSearch"
                          aria-label="Пошук"
                          className="me-2"
                          onChange={handleNameFilterChange}
                          value={nameFilterSearch}
                          onKeyDown={keyDown}
                          autoComplete="off"
                        />
                      </CCol>

                      <CCol md={4} className="filter-field">
                        <CFormInput
                          placeholder="ЄДРПОУ"
                          id="edrpouFilterSearch"
                          aria-label="Пошук"
                          className="me-2"
                          onChange={handleEdrpouFilterSearch}
                          value={edrpouFilterSearch}
                          onKeyDown={keyDown}
                          autoComplete="off"
                        />
                      </CCol>

                      <CCol md={4} className="filter-field">
                        <Select
                          options={optionsStatus}
                          value={statusFilterSearch}
                          onChange={handleChangeStatus}
                          className="flex-grow-1"
                          placeholder="Статус дозволів"
                        />
                      </CCol>




                    </CRow>


                  </CCol>

                  <CCol md={3} style={{ textAlign: 'end' }}>
                    <CButton
                      className="me-2"
                      type="submit"
                      color="secondary"
                      onClick={handleFilterSearch}
                      disabled={disabled}
                    >
                      Пошук
                    </CButton>
                    <CButton type="submit" color="secondary" onClick={handleFilterReset}>
                      X
                    </CButton>
                  </CCol>
                </CRow>
              </CAccordionBody>
            </CAccordionItem>
          </CAccordion>

          <CCardHeader>
            <strong>Контрагенти</strong> <small></small>
          </CCardHeader>
          <CCardBody>
            <div style={{ height: 400, width: '100%' }}>
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={30}
                rowsPerPageOptions={[30]}
                pagination
                rowCount={rowCountState}
                paginationMode="server"
                onPageChange={handlePageChange}
              />
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  )
}

export default Accounts
