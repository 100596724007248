import React, { useEffect, useRef, useState } from 'react'
import { Link, useLoaderData } from 'react-router-dom'
import AxiosInstance from 'src/components/Axios'
import { useNavigate } from 'react-router-dom'
import { CForm, CFormLabel, CFormInput, CButton, CCol, CImage, CInputGroup } from '@coreui/react'
import AsyncSelect from 'react-select/async'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import MyToastify from 'src/components/myComponents/MyToactify'
import css from './WorkersForm.module.scss'
import CIcon from '@coreui/icons-react'
import { cilTrash } from '@coreui/icons'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { MdOutlineAddAPhoto } from 'react-icons/md'
import { BsFillPersonFill } from 'react-icons/bs'

const urlApi = '/workers'
const urlApi_2 = '/account_workers'
const urlRouter = '/workers'
const urlRouterUpdate = '/workers/update'
const urlMedia = '/media_objects'

// токен
const tokenStr = localStorage.getItem('user')
const header = { headers: { Authorization: `Bearer ${tokenStr}`, accept: 'application/json' } }
const role = localStorage.getItem('user_role')
// id користувача
const userData = JSON.parse(localStorage.getItem('user_data'))
// console.log('userData',userData);

export async function LoaderWorkersUpdate({ params }) {
  const id = params.pid

  if (Object.keys(params).length !== 0) {
    try {
      const response1 = await AxiosInstance.get(`${urlApi}/${id}`, header)

      const response2 = await AxiosInstance.get(
        `${urlApi_2}?worker.id=${response1.data.id}`,
        header,
      )

      return { data: response1.data, data2: response2.data }
    } catch (error) {
      console.error('Помилка під час виконання запитів', error)
      return null
    }
  }

  return null
}

const WorkersForm = () => {
  const navigate = useNavigate()
  // дані для оновлення перші

  const { data, data2 } = useLoaderData() || {}
  // console.log('data', data)
  // console.log('дані контрагента', data2)

  // const [selectedOption, setSelectedOption] = useState(data2 && data2.length > 0 ? { label: data2[0].account.name, id: data2[0].account.id } : null)
  const [selectedOption, setSelectedOption] = useState(
    data2 && data2.length > 0 ? { label: data2[0].account.name, id: data2[0].account.id } : null,
  )
  const [file, setFile] = useState(null)
  const [photo, setPhoto] = useState(null)
  // const [showAddWorkers, setShowAddWorkers] = useState(false)
  const [ipn, setIpn] = useState(data ? data.codeIpn : '')
  const [arrFile, setArrFile] = useState(data2 ? [...data2[0].media] : [])
  const [combinedSeries, setCombinedSeries] = useState({ series: '', number: '' })
  const [combinedDate, setCombinedDate] = useState({ day: '00', month: '00', year: '1900' })
  const [dateOfBirthWorker, setDateOfBirthWorker] = useState('')
  const [dataState, setDataState] = useState(data)
  const [workerId, setWorkerId] = useState(data?.id != undefined ? data.id : '')
  const [workerPhoto, setWorkerPhoto] = useState('')
  // Для видалення старого фото після завантаження нового
  const [deleteOldPhotoId, setdeleteOldPhotoId] = useState(
    data?.photo?.id != undefined ? data.photo.id : '',
  )
  const [inputValue, setInputValue] = useState('')

  // Після створення працівника взяти його id для додавання зображення (аватара)
  useEffect(() => {
    if (data !== undefined && data?.id !== undefined) {
      // console.log('data', data);
      setWorkerId(data.id)
    }
  }, [data])

  useEffect(() => {
    console.log('dataState', dataState)
  }, [dataState])

  useEffect(() => {
    const { year, month, day } = combinedDate

    // Перевірка чи на цифри
    if (!isNaN(year) && !isNaN(month) && !isNaN(day)) {
      const formattedDate = new Date(Number(year), Number(month) - 1, Number(day) + 1).toISOString()
      setDateOfBirthWorker(formattedDate)
    } else {
      console.error('Invalid date format:', combinedDate)
    }
  }, [combinedDate])

  // Для відслідковування зміни вхідних даних і відображення коректного фото працівника
  useEffect(() => {
    // console.log('dataState', dataState)
    // console.log('workerPhoto', workerPhoto);
    // Заповнити фото працівника
    if (dataState?.photo?.contentUrl) {
      setWorkerPhoto(dataState?.photo?.contentUrl)
    }

    // Заповнити іd працівника
    if (dataState != undefined) {
      setWorkerId(dataState.id)
    }
  }, [dataState])

  const parseDateString = (dateString) => {
    const date = new Date(dateString)
    const day = date.getDate().toString().padStart(2, '0')
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const year = date.getFullYear().toString()

    return { day, month, year }
  }

  useEffect(() => {
    if (data?.dateOfBirth) {
      const parsedDate = parseDateString(data.dateOfBirth)
      setCombinedDate(parsedDate)
    }
  }, [])

  const parseSeriesString = (seriesString) => {
    const [series, number] = seriesString.split(' ')
    return { series, number }
  }

  useEffect(() => {
    if (data?.seriaPasport) {
      const parsedSeries = parseSeriesString(data.seriaPasport)
      setCombinedSeries(parsedSeries)
    }
  }, [])

  // Викликає popup з власного компонента
  const popup = MyToastify()
  // Текст для popup
  const popupTextPostSuccess = 'Працівника створено'
  const popupTextWorkerSuccess = 'Працівника додано'
  const popupTextPutSuccess = 'Працівника оновлено'
  const popupTextDeleteSuccess = 'Файл видалено'
  const popupTextAddInput = 'Виберіть контрагента'
  const popupTextNotAdd = 'Цей контрагент уже присвоєний'
  // поточна дата
  const currentDate = new Date()

  const handleSubmit = (event) => {
    console.log('відправляю дані форми')
    event.preventDefault()

    if (!selectedOption) {
      popup.toastifyWarning(popupTextAddInput)
      return
    }

    // дані для оновлення працівника
    const data_response_1 = {
      codeIpn: dataState.codeIpn,
      fullName: dataState.fullName,
      description: dataState.description,
      phone: dataState.phone,
      position: dataState.position,
      dateOfBirth: dateOfBirthWorker,
      seriaPasport: `${combinedSeries.series} ${combinedSeries.number}`,
      dateEntered: currentDate.toISOString(),
      status: 'changed',
    }

    // дані для створення працівника
    const data_response_3 = {
      codeIpn: dataState.codeIpn,
      fullName: dataState.fullName,
      description: dataState.description,
      phone: dataState.phone,
      position: dataState.position,
      dateOfBirth: dateOfBirthWorker,
      seriaPasport: `${combinedSeries.series} ${combinedSeries.number}`,
      dateEntered: currentDate.toISOString(),
      status: 'new',
    }

    // console.log("dataState", dataState);

    // якщо працівник є оновлюємо
    if (data) {
      // Оновити дані працівника
      console.log('є дані пуск 1')
      // console.log('data_response_1', data_response_1);
      const response = AxiosInstance.put(`${urlApi}/${data.id}`, data_response_1, header).then(
        (response) => {
          if (response.status === 200) {
            // Popup про успішні зміни
            popup.toastifySuccess(popupTextPutSuccess)
            // return navigate(urlRouter)
            return navigate(`${urlRouterUpdate}/${response.data.id}`)
          }
        },
      )
    }

    // else if (dataState.id) {

    // якщо працівник вже є то створи звязок для нього
    if (dataState.id) {
      console.log('dataState є дані пуск 2', dataState)

      const data_response_2 = {
        account: `/api/accounts/${selectedOption.value}`, //ід контрагента   це звязок
        worker: {
          id: dataState.id, //дані працівника
          codeIpn: dataState.codeIpn,
          fullName: dataState.fullName,
          dateEntered: dataState.dateEntered,
          description: dataState.description,
          phone: dataState.phone,
          status: dataState?.status ? dataState.status : 'changed',
        },
      }

      // перевірка тут

      // 1 перевірка чи має вже акаунт цього працівника у звязку акунт_воркер ==== dataState.id,
      // 2 якщо має то пропусти
      // 3 якщо нема то створи звязок

      AxiosInstance.get(`${urlApi_2}?worker.id=${dataState.id}`, header).then((response) => {
        console.log('response', response.data)

        const hasReletionship = response.data.some((el) => el.account.id === selectedOption.value)

        if (hasReletionship) {
          console.log('є у цього контрагента')
          return
        } else {
          console.log('немає у цього контрагента')
          // створення звязку
          AxiosInstance.post(`${urlApi_2}`, data_response_2, header).then((response) => {})
        }
      })

      // Popup про успішні зміни
      popup.toastifyWarning(popupTextNotAdd)

      return navigate(`${urlRouterUpdate}/${dataState.id}`)
    } else {
      // Створити працівника
      console.log('є дані пуск 3')
      // console.log('data_response_3', data_response_3);
      // якщо працівник відсутній
      const response = AxiosInstance.post(`${urlApi}`, data_response_3, header).then((response) => {
        if (response.status === 201) {
          // ствонення акаунт воркер
          const data_response_2 = {
            account: `/api/accounts/${selectedOption.value}`,
            worker: {
              id: response.data.id,
              codeIpn: response.data.codeIpn,
              fullName: response.data.fullName,
              dateEntered: response.data.dateEntered,
              description: response.data.description,
              phone: response.data.phone,
              status: response.data.status,
            },
          }

          AxiosInstance.post(`${urlApi_2}`, data_response_2, header).then((response) => {})

          // Popup про успішні зміни
          popup.toastifySuccess(popupTextWorkerSuccess)

          return navigate(`${urlRouterUpdate}/${response.data.id}`)
        }
      })
    }
  }

  // запит зєднати існуючого працівника з підрядником
  const handleSubmitExisting = (event) => {
    event.preventDefault()

    // якщо працівник є
    if (data) {
      console.log('тут є дані їх можна оновити ')
    } else {
      const accountWorkerExisting = {
        account: `/api/accounts/${selectedOption.id}`, //id до кого прикріпити
        worker: `/api/workers/${dataState.id}`,
      }

      AxiosInstance.post(`${urlApi_2}`, accountWorkerExisting, header).then((response) => {})

      // Popup про успішні зміни
      popup.toastifySuccess(popupTextPostSuccess)

      return navigate(urlRouter)
      // return navigate(`${urlRouterUpdate}/${dataState.id}`)
    }
  }

  const loadOptions = async (inputValue) => {
    if (role === 'ROLE_ADMIN') {
      // return await AxiosInstance.get(`/accounts?user.id=${userData.id}`, header).then((response) => {

      // return await AxiosInstance.get(`/accounts`, header).then((response) => {
      // пошук за введеними даними в полі введення
      return await AxiosInstance.get(`/accounts?name=${inputValue}`, header).then((response) => {
        // console.log('response.data', response.data);

        return response.data.map((result) => ({
          label: result.name,
          value: result.id,
        }))
      })
    } else {
      //якщо я зареєстрований але не адмін
      if (userData.id) {
        return await AxiosInstance.get(`/accounts?user.id=${userData.id}`, header).then(
          (response) => {
            // console.log(123131231231, response.data);

            return response.data.map((result) => ({
              label: result.name,
              value: result.id,
            }))
          },
        )
      }
    }
  }

  // при зміні опції
  const handleChangePartner = (select) => {
    setSelectedOption(select)
  }

  // const value = event.target.value;
  // console.log('Введений текст:', value);
  // setInputValue(value);

  // додавання файлів документів
  const handleFileChange = (event) => {
    const myFile = event.target.files[0]

    setFile(myFile)
  }

  // додавання фото
  const handlePhotoChange = (event) => {
    const selectedPhoto = event.target.files[0];
  
    // Перевірка, чи обраний файл є файлом у форматі JPEG або PNG
    if (selectedPhoto && (selectedPhoto.type === 'image/jpeg' || selectedPhoto.type === 'image/png')) {
      // Перевірка, чи розмір файлу не перевищує 10 МБ
      if (selectedPhoto.size <= 1024 * 1024 * 10) { // 9 MB
        setPhoto(selectedPhoto);
        // console.log('Фото успішно вибрано:', selectedPhoto);
        popup.toastifySuccess('Фото успішно вибрано:')
      } else {
        // console.log('Розмір файлу перевищує 10 МБ.');
        popup.toastifyWarning('Розмір файлу перевищує 10 МБ.')
      }
    } else {
      // console.log('Оберіть файл у форматі JPEG або PNG.');
      popup.toastifyWarning('Оберіть файл у форматі JPEG або PNG.')
    }
  }

  // відправити файл
  const handleSubmitAddFile = async (e) => {
    e.preventDefault()

    try {
      // Отримати id звязку
      const responseAccount = await AxiosInstance.get(`${urlApi_2}?worker.id=${data.id}`, header)
      const accountId = responseAccount.data[0].id

      // Створити URL для відправки файлу
      const url = process.env.REACT_APP_SERVER_URL + '/api/media_objects?accountWorker=' + accountId

      if (file) {
        const formData = new FormData()
        formData.append('file', file)
        formData.append('name', file.name)
        formData.append('accountWorker', accountId)

        // Відправити файл
        const responseFile = await AxiosInstance.post(url, formData, header)
      }

      // Отримати оновлені дані після завантаження файлу
      const responseUpdatedData = await AxiosInstance.get(
        `${urlApi_2}?worker.id=${data.id}`,
        header,
      )

      setArrFile([...responseUpdatedData.data[0].media])
    } catch (error) {
      console.error('Сталася помилка під час обробки запиту', error)
    }
  }

  const deleteFile = async (id) => {
    try {
      await confirmAlert({
        title: 'Ви впевнені що хочите видалити цей пункт ?',
        message: 'Are you sure to do this.',
        buttons: [
          {
            label: 'Так',
            onClick: async () => {
              // Видалення файлу
              await AxiosInstance.delete(`${urlMedia}/${id}`, header)
              popup.toastifySuccess(popupTextDeleteSuccess)

              // Отримання оновлених даних після видалення файлу
              const response = await AxiosInstance.get(`${urlApi_2}?worker.id=${data.id}`, header)

              setArrFile([...response.data[0].media])
            },
          },
          {
            label: 'Ні',
            onClick: () => {
              return
            },
          },
        ],
      })
    } catch (error) {
      console.error('Помилка видалення:', error)
    }
  }

  // додаємо аватар
  const handleAddPhoto = async () => {
    try {
      // Створити URL для відправки файлу з фото
      const url = process.env.REACT_APP_SERVER_URL + '/api/media_objects'

      if (photo) {
        const formData = new FormData()
        formData.append('file', photo)
        formData.append('name', photo.name)

        // Відправити файл з фото
        const responsePhoto = await AxiosInstance.post(url, formData, header)

        // закидуєм на воркер створене фото
        if (responsePhoto.status === 201) {
          // Іd фото для можливого подільшого видалення після завантаження нового
          setdeleteOldPhotoId(responsePhoto.data.id)

          const data_response = {
            photo: `/api/media_objects/${responsePhoto.data.id}`,
          }

          const responseAddPhoto = AxiosInstance.patch(
            // `${urlApi}/${data.id}`,
            `${urlApi}/${workerId}`,
            data_response,
            header,
          )

          // видалення старого фото
          // if (data.photo.id) {
          if (deleteOldPhotoId) {
            // const deleteOldPhoto = AxiosInstance.delete(`${url}/${data.photo.id}`, header)
            const deleteOldPhoto = AxiosInstance.delete(`${url}/${deleteOldPhotoId}`, header)
          }
        }
      }

      // Отримати оновлені дані після завантаження файлу
      // const response1 = await AxiosInstance.get(`${urlApi}/${data.id}`, header)
      const response1 = await AxiosInstance.get(`${urlApi}/${workerId}`, header)

      setDataState(response1.data)
    } catch (error) {
      console.error('Сталася помилка під час обробки запиту', error)
    }
  }

  const handleChange = (e, key, state, setState) => {
    setState({
      ...state,
      [key]: e.target.value,
    })
  }

  useEffect(() => {
    // console.log('ipn', ipn);
  }, [ipn])

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      handleIpnChange(ipn)
    }, 1000)

    return () => clearTimeout(timeoutId)
  }, [ipn])

  // пошук по іпн
  const handleIpnChange = async (value) => {
    // якщо нічого не введено перерви
    if (!value) {
      setDataState({ codeIpn: value })
      return
    }

      // Перевірка довжини введеного значення (не більше 10 цифр)
  if (value.length > 10) {
    // console.log('Введено занадто багато цифр');
    popup.toastifyWarning('Введено занадто багато цифр необхідно 10 цифр')
    return;
  }

  // Перевірка, чи введене значення складається лише з цифр
  if (!/^\d+$/.test(value)) {
    popup.toastifyWarning('Введено не числові значення необхідно 10 цифр')
    return;
  }

    try {
      const response = await AxiosInstance.get(`${urlApi}?codeIpn=${value}`)
      const dataById = response.data['hydra:member']?.[0]
      console.log('якщо ід введено запит', dataById)

      if (dataById?.codeIpn === value) {
        // якщо є відповідні дані, встановлюємо їх
        setDataState(dataById)
        console.log('є такий')
      } else {
        // якщо немає відповідних даних, встановлюємо { codeIpn: value }
        setDataState({ codeIpn: value })
        setCombinedDate({ day: '', month: '', year: '' })
        setCombinedSeries({ series: '', number: '' })
        console.log('нема такого')
      }
    } catch (error) {
      console.error('Помилка при отриманні даних:', error)
    }
  }



  return (
    <>
      {/* Popup про успішні зміни (справа зверху) */}
      <ToastContainer />

      {/* форма існуючого працівника */}

      <CForm onSubmit={handleSubmit} className="mb-3">
        <div className="d-flex">
          <div style={{ maxWidth: '500px', marginRight: '100px' }}>
            <div className="mb-3" style={{ maxWidth: '390px' }}>
              <CFormLabel htmlFor="exampleFormControlInput5">
                {dataState?.id ? <p>Працівник з таким ІПН існує</p> : <p>ІПН працівника</p>}
              </CFormLabel>
              <CFormInput
                type="text"
                id="exampleFormControlInput5"
                value={ipn}
                disabled={data}
                onChange={(e) => setIpn(e.target.value)}
                // onChange={handleIpnChange}
                autoComplete="off"
                required
              />
            </div>

            <div className="mb-3">
              <CFormLabel htmlFor="exampleFormControlInput1">Контрагент</CFormLabel>
              <AsyncSelect
                cacheOptions
                loadOptions={loadOptions}
                defaultOptions
                onInputChange={(inputValue) => setInputValue(inputValue)}
                value={selectedOption}
                onChange={handleChangePartner}
                className="flex-grow-1"
              />
            </div>

            <div className="mb-3">
              <CFormLabel htmlFor="exampleFormControlInput1">Посада</CFormLabel>
              <CFormInput
                type="text"
                id="exampleFormControlInput1"
                value={dataState?.position ? dataState.position : ''}
                disabled={
                  (data && role !== 'ROLE_ADMIN') || (dataState?.id && role !== 'ROLE_ADMIN')
                }
                autoComplete="off"
                onChange={(e) => handleChange(e, 'position', dataState, setDataState)}
                required
              />
            </div>

            <div className="mb-3">
              <CFormLabel htmlFor="exampleFormControlInput1">ПІБ</CFormLabel>
              <CFormInput
                type="text"
                id="exampleFormControlInput1"
                value={dataState?.fullName ? dataState.fullName : ''}
                // disabled={data &&  role !== 'ROLE_ADMIN'}
                disabled={
                  (data && role !== 'ROLE_ADMIN') || (dataState?.id && role !== 'ROLE_ADMIN')
                }
                autoComplete="off"
                onChange={(e) => handleChange(e, 'fullName', dataState, setDataState)}
                required
              />
            </div>

            <div className="mb-3">
              <CFormLabel htmlFor="exampleFormControlInput1">Дата та рік народження</CFormLabel>

              <div className="d-flex align-items-center">
                <CFormInput
                  style={{ marginRight: '10px', width: '50px' }}
                  type="text"
                  id="yearOfBirthInput"
                  value={combinedDate.day}
                  // disabled={data && role !== 'ROLE_ADMIN'}
                  autoComplete="off"
                  onChange={(e) => handleChange(e, 'day', combinedDate, setCombinedDate)}
                  required
                />
                <CFormInput
                  style={{ marginRight: '10px', width: '50px' }}
                  type="text"
                  id="yearOfBirthInput"
                  value={combinedDate.month}
                  // disabled={data && role !== 'ROLE_ADMIN'}
                  autoComplete="off"
                  onChange={(e) => handleChange(e, 'month', combinedDate, setCombinedDate)}
                  required
                />
                <CFormInput
                  style={{ marginRight: '10px', width: '60px' }}
                  type="text"
                  id="yearOfBirthInput"
                  value={combinedDate.year}
                  // disabled={data && role !== 'ROLE_ADMIN'}
                  autoComplete="off"
                  onChange={(e) => handleChange(e, 'year', combinedDate, setCombinedDate)}
                  required
                />
              </div>
            </div>

            <div className="mb-3">
              <CFormLabel htmlFor="exampleFormControlInput1">
                Серія та номер паспорта (для ID картки лише номер)
              </CFormLabel>

              <div className="d-flex align-items-center">
                <CFormInput
                  style={{ marginRight: '10px', width: '50px' }}
                  type="text"
                  id="series"
                  value={combinedSeries.series}
                  // disabled={data && role !== 'ROLE_ADMIN'}
                  autoComplete="off"
                  onChange={(e) => handleChange(e, 'series', combinedSeries, setCombinedSeries)}
                  // required
                />
                <CFormInput
                  style={{ marginRight: '10px', width: '100px' }}
                  type="text"
                  id="number"
                  value={combinedSeries.number}
                  // disabled={data && role !== 'ROLE_ADMIN'}
                  autoComplete="off"
                  onChange={(e) => handleChange(e, 'number', combinedSeries, setCombinedSeries)}
                  required
                />
              </div>
            </div>

            <div className="mb-3">
              <CFormLabel htmlFor="exampleFormControlInput1">Телефон</CFormLabel>
              <CFormInput
                type="text"
                id="exampleFormControlInput1"
                value={dataState?.phone ? dataState.phone : ''}
                // disabled={data && role !== 'ROLE_ADMIN'}
                autoComplete="off"
                onChange={(e) => handleChange(e, 'phone', dataState, setDataState)}
                required
              />
            </div>

            <div className="mb-3">
              <CFormLabel htmlFor="exampleFormControlInput1">Коментар</CFormLabel>
              <CFormInput
                type="text"
                id="exampleFormControlInput1"
                value={dataState?.description ? dataState.description : ''}
                // disabled={role !== 'ROLE_ADMIN'}
                autoComplete="off"
                onChange={(e) => handleChange(e, 'description', dataState, setDataState)}
              />
            </div>

            {/* {role === 'ROLE_ADMIN' ? ( */}
            <div className="mb-3">
              <CButton type="submit" color="secondary">
                {dataState?.id ? 'Оновити дані працівника' : 'Додати працівника'}
              </CButton>
            </div>
            {/* ) : (
              ''
            )} */}
          </div>

          {dataState?.id || data ? (
            <div style={{ maxWidth: '300px' }}>
              <div className="mb-3" className={`${css.overlayWrapper} mb-3`}>
                {workerPhoto ? (
                  <img
                    // src={process.env.REACT_APP_SERVER_URL + dataState.photo.contentUrl}
                    src={process.env.REACT_APP_SERVER_URL + workerPhoto}
                    width="100%"
                    height="100%"
                    alt="фото"
                    style={{ objectFit: 'cover' }}
                  />
                ) : (
                  <BsFillPersonFill size={250} />
                )}

                <div className={css.overlay}>
                  <p>
                    - Особа повинна дивитися в камеру. <br/>
                    - Особа не повинна носити маски, головні убори чи пов’язки на очах. <br/>
                    - Особа не повинна піднімати голову або нахилятися. <br/>
                    - Особа не повинна носити густий макіяж. <br/>
                    - Особа не повинна мати закриті очі. <br/>
                    - Переконайтеся, щоб обидва плечі особи були на фото. <br/>
                    - Перек онайтеся, щоб на фото не було два обличчя. <br/>
                  </p>
                </div>

              </div>

              {/* Додавання фото */}
              <div className="mb-3">
                <CFormInput
                  type="file"
                  id="exampleFormControlFile1"
                  onChange={handlePhotoChange}
                  multiple
                />
              </div>

              <div>
                <CButton onClick={handleAddPhoto} color="success" disabled={!photo ? true : false}>
                  Додати фото
                </CButton>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>

        {dataState?.id || data ? (
          <div>
            <div className="mb-3" style={{ maxWidth: '400px' }}>
              <CFormLabel htmlFor="exampleFormControlFile1">Виберіть файли</CFormLabel>
              <CFormInput
                type="file"
                id="exampleFormControlFile1"
                onChange={handleFileChange}
                multiple
              />
            </div>

            <div>
              <CButton
                onClick={handleSubmitAddFile}
                color="success"
                disabled={!file ? true : false}
              >
                Завантажити документи
              </CButton>
              <Link to={urlRouter} className="ms-3">
                <CButton type="submit" color="secondary">
                  Відміна
                </CButton>
              </Link>
            </div>

            <div className={css.wrapperButton}>
              <p>Наявні документи</p>
              {arrFile.map((el, index) => (
                <div className={css.file_block} key={index}>
                  {el.contentUrl.endsWith('.jpg') ? (
                    // <img
                    //   src={process.env.REACT_APP_SERVER_URL + el.contentUrl}
                    //   width="50px"
                    //   alt={`Image ${index}`}
                    // />

                    <a
                      href={process.env.REACT_APP_SERVER_URL + el.contentUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {el.contentUrl}
                    </a>
                  ) : (
                    <a
                      href={process.env.REACT_APP_SERVER_URL + el.contentUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {el.contentUrl}
                    </a>
                  )}

                  <CButton color="danger" variant="outline" onClick={() => deleteFile(el.id)}>
                    <CIcon icon={cilTrash} customClassName="nav-icon" height={20} />
                  </CButton>
                </div>
              ))}
            </div>
          </div>
        ) : (
          ''
        )}
      </CForm>
    </>
  )
}

export default WorkersForm
